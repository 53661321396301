import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
// import { makeStyles } from '@mui/material/style';

// const useStyles = makeStyles((theme) => ({
//   loader: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     zIndex: 9999,
//   },
// }));

const Loader = ({ setValues, values }) => {
  // const classes = useStyles();

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={values.loading}
      // onClick={() => setValues({ ...values, loading: !values.loading })}
    >
      <CircularProgress size={80} thickness={5} />
    </Backdrop>
  );
};

export default Loader;