// ** React Imports
import { useEffect, useState } from 'react'

// ** MUI Imports
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Stepper from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import MuiStep from '@mui/material/Step'
import CardContent from '@mui/material/CardContent'

// ** Third Party Imports
import toast from 'react-hot-toast'

// ** Icon Imports
import Icon from '../../@core/components/icon'

// ** Custom Components Imports
import StepperCustomDot from './StepperCustomDot'
import CustomAvatar from '../../@core/components/mui/avatar'

// ** Util Import
import { hexToRGBA } from '../../@core/utils/hex-to-rgba'

// ** Styled Component
import StepperWrapper from '../../@core/styles/mui/stepper'
import StepPersonalDetails from './StepPersonalDetails'
import { Box, Button, Grid } from '@mui/material'
import PaymentDetails from '../PaymentDetails'
import UploadDocument from '../UploadDocument'
import Occupation from '../Occupation'
import Nomination from '../Nomination'
import PersonalInfo from '../PersonalInfo'
import { APIURL, PAYMENT_RETURN_URL, resHashKey } from '../../commonUrl'
import axios from 'axios';
import moment from 'moment'
import KYC from '../KYC'
import _ from 'lodash'
import { Buffer } from 'buffer';
import UploadDigilocker from '../UploadDigilocker'
import { isDesktop } from 'react-device-detect'
import ConfirmDetails from '../ConfimDetails'
import Loader from '../../Loader'
const { v4: uuidv4 } = require('uuid');
const { StringDecoder } = require('string_decoder');
const decoder = new StringDecoder('utf8');

const steps = [
	{
		icon: 'tabler:home',
		title: 'Choices',
		subtitle: 'Add Your Trading Choices',
		substep: []
	},
	{
		icon: 'tabler:user',
		title: 'Upload Documents',
		subtitle: 'Upload Required Documents',
		substep: [
			{
				icon: 'tabler:user',
				title: 'Pan Card',
				subtitle: 'Upload Required Documents',
			},
			{
				icon: 'tabler:user',
				title: 'Aadhar Card',
				subtitle: 'Upload Required Documents',
			},
			{
				icon: 'tabler:user',
				title: 'Bank',
				subtitle: 'Upload Required Documents',
			},
			{
				icon: 'tabler:user',
				title: 'Income Proof',
				subtitle: 'Upload Required Documents',
			},
			{
				icon: 'tabler:user',
				title: 'Signature',
				subtitle: 'Upload Required Documents',
			},

		]
	},
	{
		icon: 'tabler:link',
		title: 'Personal Info',
		subtitle: 'Add Personal Info',
		substep: []
	},
	{
		icon: 'tabler:link',
		title: 'Occupation',
		subtitle: 'Add Your Occupation Details',
		substep: []
	},
	{
		icon: 'tabler:link',
		title: 'Nomination',
		subtitle: 'Add Yout Nomination Details',
		substep: []
	},
	{
		icon: 'tabler:user',
		title: 'Pan Card',
		subtitle: 'Upload Required Documents',
	},
	{
		icon: 'tabler:user',
		title: 'Aadhar Card',
		subtitle: 'Upload Required Documents',
	},
	{
		icon: 'tabler:user',
		title: 'Bank',
		subtitle: 'Upload Required Documents',
	},
	{
		icon: 'tabler:user',
		title: 'Bank',
		subtitle: 'Upload Required Documents',
	},
]

const StepperHeaderContainer = styled(CardContent)(({ theme }) => ({
	borderRight: `1px solid ${theme.palette.divider}`,
	[theme.breakpoints.down('md')]: {
		borderRight: 0,
		borderBottom: `1px solid ${theme.palette.divider}`
	}
}))

const Step = styled(MuiStep)(({ theme }) => ({
	'& .MuiStepLabel-root': {
		paddingTop: 0
	},
	'&:not(:last-of-type) .MuiStepLabel-root': {
		paddingBottom: theme.spacing(3)
	},
	'&:last-of-type .MuiStepLabel-root': {
		paddingBottom: 0
	},
	'& .MuiStepLabel-iconContainer': {
		display: 'none'
	},
	'& .step-subtitle': {
		color: `${theme.palette.text.disabled} !important`,
		fontSize: '0.75rem !important'
	},
	'& + svg': {
		color: theme.palette.text.disabled
	},
	'&.Mui-completed .step-title': {
		color: theme.palette.text.disabled
	}
}))

const StepperCustomVertical = ({ setValues, values, errors, setErrors, setLoginStep }) => {
	const delay = ms => new Promise(res => setTimeout(res, ms));
	var userData = JSON.parse(localStorage.getItem('EKYCData'))
	var nextStep = 0
	if (global.myCache.get('digiLockerStepStatus') == 1) {
		nextStep = 1
		// global.myCache.set('digiLockerStepStatus', 0, 0)
	}
	if (userData.appStep) {
		nextStep = userData.appStep - 5
	}
	// console.log(nextStep);
	const [activeStep, setActiveStep] = useState(nextStep);
	// console.log(values);

	const getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
		};
	}

	function getBase64Nominee(file, type) {
		if (file && file != "") {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				global.myCache.set(type, reader.result, 0)
			};
			reader.onerror = function (error) {
			};
		}
	}

	useEffect(() => {
		if (isDesktop) {
			if (global.as) {
				global.as.onmessage = (message) => {
					var msg = JSON.parse(decoder.write(Buffer.from(message.data)));
					// console.log(msg)
					// console.log(msg.type)
					// console.log(msg.data)
					if (msg.type == 'payment') {
						setValues({ ...values, loading: false })
						handlePaymentStatus(msg.data)
					}

				}
			}
		}
	}, [global.as]);

	const handlePaymentStatus = async (paymentdata) => {
		let data = {
			email: userData.email,
			payment: paymentdata,
			equity: values.equity ? 1 : 0,
			fno: values.fno ? 1 : 0,
			currency: values.currency ? 1 : 0,
			comodity: values.comodity ? 1 : 0,
			poa: values.poa ? 1 : 0,
			poacharge: values.poacharge,
			finalamount: values.finalAmount
		}

		let config = {
			method: 'post',
			url: APIURL + 'updatePayment',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		await axios.request(config)
			.then((response) => {
				if (paymentdata.desc == 'SUCCESS') {
					var result = response.data
					if (result.status == true) {
						setLoginStep(15)
						userData.appStep = 15
						localStorage.setItem('EKYCData', JSON.stringify(userData))
						// setActiveStep(activeStep + 1)
					} else {

					}
				} else {
					setErrors({ ...errors, paymentError: true })
				}
			})
	}

	// Handle Stepper
	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const handleNext = async () => {
		/* if (activeStep === steps.length - 1) {
			toast.success('Form Submitted')
		}
		else {
			setActiveStep(prevActiveStep => prevActiveStep + 1)
		} */
		var passdata = {}

		var step = activeStep + 1
		if (activeStep == 0) {
			setValues({ ...values, loading: true })
			var MerchantLogin = "197";
			var MerchantPass = "Test@123";
			var TransactionType = "NBFundtransfer";
			var ProductID = "NSE";
			var TransactionID = uuidv4();
			var TransactionCurrency = "INR";
			var BankID = "2001";
			var TransactionServiceCharge = "0";
			var TransactionDateTime = moment().format("DD/MM/YYYY");
			var CustomerAccountNo = "1234567890";
			var MerchantDiscretionaryData = "";
			var ru = PAYMENT_RETURN_URL + 'makepayment'


			var amount = parseFloat(values.finalAmount) + parseFloat(values.poacharge)
			let config = {
				method: 'post',
				url: APIURL + 'generateSignature',
				headers: {
					'Content-Type': 'application/json'
				},
				data: { amount: amount, str: TransactionID }
			};

			await axios.request(config)
				.then((response) => {
					// return response.data.sign
					console.log(values);
					var atomUrl = `https://paynetzuat.atomtech.in/paynetz/epi/fts?login=[MerchantLogin]&pass=[MerchantPass]&ttype=[TransactionType]&prodid=[ProductID]&amt=[TransactionAmount]&txncurr=[TransactionCurrency]&txnscamt=[TransactionServiceCharge]&clientcode=[ClientCode]&txnid=[TransactionID]&date=[TransactionDateTime]&custacc=[CustomerAccountNo]&ru=[ru]&signature=[signature]&udf5=[clientcode]`
					atomUrl = _.replace(atomUrl, '[MerchantLogin]', MerchantLogin)
					atomUrl = _.replace(atomUrl, '[MerchantPass]', MerchantPass)
					atomUrl = _.replace(atomUrl, '[TransactionType]', TransactionType)
					atomUrl = _.replace(atomUrl, '[ProductID]', ProductID)
					atomUrl = _.replace(atomUrl, '[TransactionAmount]', amount.toFixed(2))
					atomUrl = _.replace(atomUrl, '[TransactionCurrency]', TransactionCurrency)
					atomUrl = _.replace(atomUrl, '[TransactionServiceCharge]', TransactionServiceCharge)
					atomUrl = _.replace(atomUrl, '[ClientCode]', values.applicatinono)
					atomUrl = _.replace(atomUrl, '[TransactionID]', TransactionID)
					atomUrl = _.replace(atomUrl, '[TransactionDateTime]', TransactionDateTime)
					atomUrl = _.replace(atomUrl, '[CustomerAccountNo]', CustomerAccountNo)
					// atomUrl = _.replace(atomUrl, '[MerchantDiscretionaryData]', MerchantDiscretionaryData)
					// atomUrl = _.replace(atomUrl, '[BankID]', BankID)
					// const decodedHtml = encodeURI(ru)
					// console.log(decodedHtml);
					atomUrl = _.replace(atomUrl, '[ru]', ru)
					atomUrl = _.replace(atomUrl, '[signature]', response.data.sign)
					atomUrl = _.replace(atomUrl, '[clientcode]', values.clientcode)
					// debugger
					// atomUrl = 'https://paynetzuat.atomtech.in/paynetz/epi/fts?login=197&pass=Test@123&ttype=NBFundTransfer&amt=100.00&txnid=M123&prodid=NSE&txncurr=INR&clientcode=MDA3&date=26/06/2019&txnscamt=0&custacc=100000036600&ru=http://localhost:14001/makepayment&signature=704e6a78ca61c89127ca5430ddf59a329dacb142ae2790e19d676f5ca8ca80b9c534552e877bfb0ce1c2dddf252ae3d7580d329556213811f828711e9f4ea371&udf5=1AA01'
					console.log(atomUrl);
					// console.log(TransactionID);
					window.open(atomUrl, '_blank').focus();
				})
				.catch((error) => {
					console.log(error);
				})

			// https://paynetzuat.atomtech.in/paynetz/epi/fts?login=[MerchantLogin]pass=[MerchantPass]ttype=[TransactionType]prodid=[ProductID]amt=[TransactionAmount]txncurr=[TransactionCurrency]txnscamt=[TransactionServiceCharge]clientcode=[ClientCode]txnid=[TransactionID]date=[TransactionDateTime]custacc=[CustomerAccountNo]mdd=[MerchantDiscretionaryData]bankid=[BankID]ru=[ru]signature=[signature]



			// setActiveStep(step);
		} else if (activeStep == 1) {
			if (values.pancardname == "") {
				setErrors({ ...errors, pancardnameerror: true })
				return false
			}
			if (values.pancardnumber == "") {
				setErrors({ ...errors, pancardnumbererror: true })
				return false
			}
			if (values.pancarddob == "") {
				setErrors({ ...errors, pancarddoberror: true })
				return false
			}
			if (values.pancardfathername == "") {
				setErrors({ ...errors, pancardfathernameerror: true })
				return false
			}
			passdata.email = userData.email
			passdata.pancardresponse = values.pancardResponse
			passdata.pancardurl = values.pancardUrl
			passdata.kraStatus = values.kraStatus != '' ? 1 : 0
			passdata.kraObj = values.kraObj
			passdata.step = 'pancard'
			await submitData(passdata, step)

		} else if (activeStep == 2) {
			if (values.aadharcardAddressOne == null || values.aadharcardAddressOne == "") {
				setErrors({ ...errors, aadharcardAddressOneError: true })
				return false
			}
			if (values.aadharcardAddressTwo == null || values.aadharcardAddressTwo == "") {
				setErrors({ ...errors, aadharcardAddressTwoError: true })
				return false
			}
			if (values.aadharcardPinCode == null || values.aadharcardPinCode == "") {
				setErrors({ ...errors, aadharcardPinCodeError: true })
				return false
			}
			if (values.aadharcardCity == null || values.aadharcardCity == "") {
				setErrors({ ...errors, aadharcardCityError: true })
				return false
			}
			if (values.aadharcardState == null || values.aadharcardState == "") {
				setErrors({ ...errors, aadharcardStateError: true })
				return false
			}
			passdata.email = userData.email
			passdata.aadharcardresponse = values.aadharResponse
			passdata.fronturl = values.aadharFrontUrl
			passdata.backurl = values.aadharBackUrl
			passdata.addressone = values.aadharcardAddressOne
			passdata.addresstwo = values.aadharcardAddressTwo
			passdata.pincode = values.aadharcardPinCode
			passdata.city = values.aadharcardCity
			passdata.state = values.aadharcardState
			passdata.ispermanentsame = values.isPermanentSame
			passdata.permanentaddressone = values.permanentAddressOne
			passdata.permanentaddresstwo = values.permanentAddressTwo
			passdata.permanentpincode = values.permanentPinCode
			passdata.permanentcity = values.permanentCity
			passdata.permanentstate = values.permanentState
			passdata.country = values.aadharcardCountry
			passdata.step = 'aadharcard'
			await submitData(passdata, step)
		} else if (activeStep == 3) {
			if (values.ifscCode == "") {
				setErrors({ ...errors, ifscCodeError: true })
				return false
			}
			if (values.accountNumber == "") {
				setErrors({ ...errors, accountNumberError: true })
				return false
			}
			if (values.verifyAccountNumber == "") {
				setErrors({ ...errors, verifyAccountNumberError: true })
				return false
			}
			if (values.accountNumber != values.verifyAccountNumber) {
				setErrors({ ...errors, matchAccountNumberError: true })
				return false
			}
			passdata.email = userData.email
			passdata.ifscCode = values.ifscCode
			passdata.accountType = values.accountType
			passdata.accountNumber = values.accountNumber
			passdata.bankName = values.bankName
			passdata.bankBranch = values.bankBranch
			passdata.bankAddress = values.bankAddress
			passdata.bankMicr = values.bankMicr
			passdata.step = 'bank'
			await submitData(passdata, step)
		} else if (activeStep == 4) {
			if (values.incomeProofType == undefined || values.incomeProofType == '') {
				setErrors({ ...errors, incomeProofTypeError: true })
				return false
			}

			if (values.incomeProofFile.length == 0) {
				setErrors({ ...errors, incomeProofFileError: true })
				return false
			}

			await getBase64(values.incomeProofFile[0], async (result) => {
				var frontBase64 = result;
				passdata.email = userData.email
				passdata.incomeProofType = values.incomeProofType
				passdata.filebase64 = frontBase64
				passdata.extension = values.incomeProofFile[0].type
				passdata.step = 'income'
				await submitData(passdata, step)
			})

		} else if (activeStep == 5) {
			if (values.signBase64 == "") {
				setErrors({ ...errors, signError: true })
				return false
			}
			passdata.email = userData.email
			passdata.signBase64 = values.signBase64
			passdata.signExtension = values.signExtension
			passdata.step = 'sign'
			await submitData(passdata, step)
		} else if (activeStep == 6) {
			console.log(values.piGender, values.piMaritalStatus);
			if (values.piFatherFirstName == null || values.piFatherFirstName == "") {
				setErrors({ ...errors, piFatherFirstNameError: true })
				return false
			}
			if (values.piFatherMiddleName == null || values.piFatherMiddleName == "") {
				setErrors({ ...errors, piFatherMiddleNameError: true })
				return false
			}
			if (values.piFatherLastName == null || values.piFatherLastName == "") {
				setErrors({ ...errors, piFatherLastNameError: true })
				return false
			}
			if (values.piMotherFirstName == null || values.piMotherFirstName == "") {
				setErrors({ ...errors, piMotherFirstNameError: true })
				return false
			}
			if (values.piMotherMiddleName == null || values.piMotherMiddleName == "") {
				setErrors({ ...errors, piMotherMiddleNameError: true })
				return false
			}
			if (values.piMotherLastName == null || values.piMotherLastName == "") {
				setErrors({ ...errors, piMotherLastNameError: true })
				return false
			}
			if (values.piMaritalStatus == null) {
				setErrors({ ...errors, piMaritalStatusError: true })
				return false
			}
			passdata.email = userData.email
			passdata.fatherfirstname = values.piFatherFirstName
			passdata.fathermiddlename = values.piFatherMiddleName
			passdata.fatherlastname = values.piFatherLastName
			passdata.motherfirstname = values.piMotherFirstName
			passdata.mothermiddlename = values.piMotherMiddleName
			passdata.motherlastname = values.piMotherLastName
			passdata.gender = values.piGender
			passdata.maritalstatus = values.piMaritalStatus
			passdata.emailmobile = values.piEmailMobile
			passdata.emailmobiletext = values.piEmailMobileText
			// passdata.political = values.piPolitical
			// passdata.politicaltext = values.piPoliticalText
			passdata.step = 'personalinfo'
			await submitData(passdata, step)
		} else if (activeStep == 7) {
			if (values.occupation == "") {
				setErrors({ ...errors, occupationError: true })
				return false
			}
			if (values.annualIncome == "") {
				setErrors({ ...errors, annualIncomeError: true })
				return false
			}
			if (values.longTrading == "") {
				setErrors({ ...errors, longTradingError: true })
				return false
			}
			if (values.isReferral == 1 && values.referralName == "") {
				setErrors({ ...errors, referralNameError: true })
				return false
			}

			passdata.email = userData.email
			passdata.occupation = values.occupation
			passdata.annualIncome = values.annualIncome
			passdata.longTrading = values.longTrading
			passdata.isReferral = values.isReferral
			passdata.referralName = values.referralName
			passdata.isDelivarySlip = values.isDelivarySlip
			passdata.step = 'occupation'
			await submitData(passdata, step)
		} else if (activeStep == 8) {
			getBase64Nominee(values.nomineeOneIdProof[0], 'one')
			getBase64Nominee(values.nomineeOneGuardianIdProof[0], 'oneguard')
			if (values.nomineeTwoIdProof) {
				getBase64Nominee(values.nomineeTwoIdProof[0], 'two')
				getBase64Nominee(values.nomineeThreeIdProof[0], 'three')
				getBase64Nominee(values.nomineeTwoGuardianIdProof[0], 'twoguard')
				getBase64Nominee(values.nomineeThreeGuardianIdProof[0], 'threeguard')
			}
			await delay(1000)
			var nomineeArr = [
				{
					nomineeName: values.nomineeOneName,
					nomineeRelation: values.nomineeOneRelation,
					nomineeShare: values.nomineeOneShare,
					nomineeMobile: values.nomineeOneMobile,
					nomineeIdProof: global.myCache.get('one'),
					nomineeIdProofExtension: values.nomineeOneIdProof.length > 0 ? values.nomineeOneIdProof[0].type : "",
					nomineeDOB: values.nomineeOneDOB,
					nomineeRelationshipGuardian: values.nomineeOneRelationshipGuardian,
					nomineeGuardianName: values.nomineeOneGuardianName,
					nomineeGuardianMobile: values.nomineeOneGuardianMobile,
					nomineeGuardianIdProof: global.myCache.get('oneguard'),
					nomineeGuardianIdProofExtension: values.nomineeOneGuardianIdProof.length > 0 ? values.nomineeOneGuardianIdProof[0].type : "",
				},
				{
					nomineeName: values.nomineeTwoName,
					nomineeRelation: values.nomineeTwoRelation,
					nomineeShare: values.nomineeTwoShare,
					nomineeMobile: values.nomineeTwoMobile,
					nomineeIdProof: global.myCache.get('two'),
					nomineeIdProofExtension: values.nomineeTwoIdProof.length > 0 ? values.nomineeTwoIdProof[0].type : "",
					nomineeDOB: values.nomineeTwoDOB,
					nomineeRelationshipGuardian: values.nomineeTwoRelationshipGuardian,
					nomineeGuardianName: values.nomineeTwoGuardianName,
					nomineeGuardianMobile: values.nomineeTwoGuardianMobile,
					nomineeGuardianIdProof: global.myCache.get('twoguard'),
					nomineeGuardianIdProofExtension: values.nomineeTwoIdProof.length > 0 ? values.nomineeTwoIdProof[0].type : "",
				},
				{
					nomineeName: values.nomineeThreeName,
					nomineeRelation: values.nomineeThreeRelation,
					nomineeShare: values.nomineeThreeShare,
					nomineeMobile: values.nomineeThreeMobile,
					nomineeIdProof: global.myCache.get('three'),
					nomineeIdProofExtension: values.nomineeThreeIdProof.length > 0 ? values.nomineeThreeIdProof[0].type : "",
					nomineeDOB: values.nomineeThreeDOB,
					nomineeRelationshipGuardian: values.nomineeThreeRelationshipGuardian,
					nomineeGuardianName: values.nomineeThreeGuardianName,
					nomineeGuardianMobile: values.nomineeThreeGuardianMobile,
					nomineeGuardianIdProof: global.myCache.get('threeguard'),
					nomineeGuardianIdProofExtension: values.nomineeThreeIdProof.length > 0 ? values.nomineeThreeIdProof[0].type : "",
				}
			]
			passdata.email = userData.email
			passdata.isNominee = values.isNominee
			passdata.nomineeArr = nomineeArr
			passdata.step = 'nomination'
			await submitData(passdata, step)

			// setActiveStep(step);
			setLoginStep(17)
		} else {
			setLoginStep(7)
		}
	}

	const submitData = async (passdata, step) => {
		// console.log(passdata, step)
		let data = passdata;

		let config = {
			method: 'post',
			url: APIURL + 'createCustomer',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		await axios.request(config)
			.then((response) => {
				var result = response.data
				if (result.status == true) {
					if (result.laststep == 'completed') {
						setErrors({ ...errors, commonError: result.message })
					} else {
						if (result.laststep == 'emailotp') {
							setValues({ ...values, clientcode: result.clientcode })
							setValues({ ...values, clientcodepkid: result.clientcodepkid })
						}
						if (result.laststep == 'clientcode') {
							setValues({ ...values, totalAmount: result.totalamount })
							setValues({ ...values, discount: result.discount })
							setValues({ ...values, finalAmount: result.finalamount })
						}
						setActiveStep(step);
					}
					var userData = JSON.parse(localStorage.getItem('EKYCData'))
					userData.appStep = passdata.step == 'nomination' ? 17 : result.appStep
					localStorage.setItem('EKYCData', JSON.stringify(userData))
				} else {
					setErrors({ ...errors, commonError: result.message })
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const getStepContent = step => {
		switch (step) {
			case 0:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 1:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 2:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 3:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 4:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 5:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 6:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 7:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			case 8:
				return <StepPersonalDetails steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
			default:
				return <ConfirmDetails handleNext={handleNext} values={values} />
		}
	}
	{/* <KYC handleNext={handleNext} setValues={setValues} values={values} /> */ }
	const renderContent = () => {
		return (
			<>
				<Grid container spacing={5}>
					<Grid item xs={12}>
						{
							activeStep == 0 ?
								<PaymentDetails setValues={setValues} values={values} errors={errors} />
								: activeStep == 1 || activeStep == 2 || activeStep == 3 || activeStep == 4 || activeStep == 5 ?
									<UploadDocument substep={steps[activeStep]['substep']} setActiveStep={setActiveStep} activeStep={activeStep} setValues={setValues} values={values} errors={errors} />
									: activeStep == 6 ?
										<PersonalInfo setValues={setValues} values={values} errors={errors} />
										: activeStep == 7 ?
											<Occupation setValues={setValues} values={values} errors={errors} />
											: activeStep == 8 ?
												<Nomination setValues={setValues} values={values} errors={errors} />
												: null
						}

					</Grid>
				</Grid>
				{getStepContent(activeStep)}
			</>
		)
	}

	return (
		<>
			<Card sx={{ display: 'flex', boxShadow: 'none', flexDirection: { xs: 'column', md: 'row' } }}>
				<StepperHeaderContainer>
					<StepperWrapper sx={{ height: '100%' }}>
						<Stepper
							activeStep={(activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5) ? 1 : activeStep == 0 ? 0 : (activeStep - 4)}
							orientation='vertical'
							connector={<></>}
							sx={{ height: '100%', minWidth: '15rem' }}
						>
							<Step key={0} >
								<StepLabel StepIconComponent={StepperCustomDot}>
									<div className='step-label'>
										{activeStep >= 0 ? <CustomAvatar
											variant='rounded'
											{...(activeStep >= 0 && { skin: 'light' })}
											{...(activeStep === 0 && { skin: 'filled' })}
											{...(activeStep >= 0 && { color: 'primary' })}
											sx={{
												mr: '1rem !important',
												...(activeStep === 0 && { boxShadow: theme => theme.shadows[3] }),
												...(activeStep > 0 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
											}}
										>
											<Icon icon={"tabler:currency-rupee"} />
										</CustomAvatar>
											: <Avatar
												variant='rounded'
												{...(activeStep >= 0 && { skin: 'light' })}
												{...(activeStep === 0 && { skin: 'filled' })}
												{...(activeStep >= 0 && { color: 'primary' })}
												sx={{
													mr: '1rem !important',
													...(activeStep === 0 && { boxShadow: theme => theme.shadows[3] }),
													...(activeStep > 0 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
												}}
											>
												<Icon icon={"tabler:currency-rupee"} />
											</Avatar>}
										<div>
											<Typography className='step-title'>{"Choices"}</Typography>
											<Typography variant='caption' className='step-subtitle'>{"Add Your Trading Choices"}</Typography>
										</div>
									</div>
								</StepLabel>
							</Step>
							<Step key={1}  >
								<StepLabel StepIconComponent={StepperCustomDot} >
									<div className='step-label'>
										{activeStep >= 1 || activeStep >= 2 || activeStep >= 3 || activeStep >= 4 || activeStep >= 5 ? <CustomAvatar
											variant='rounded'
											{...(activeStep >= 1 && activeStep >= 2 && activeStep >= 3 && activeStep >= 4 && activeStep >= 5 && { skin: 'light' })}
											{...(activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5 && { skin: 'filled' })}
											{...(activeStep >= 1 || activeStep >= 2 || activeStep >= 3 || activeStep >= 4 || activeStep >= 5 && { color: 'primary' })}
											sx={{
												mr: '1rem !important',
												...(activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5 && { boxShadow: theme => theme.shadows[3] }),
												...(activeStep > 1 && activeStep > 2 && activeStep > 3 && activeStep > 4 && activeStep > 5 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
											}}
										>
											<Icon icon={"tabler:file-upload"} />
										</CustomAvatar>
											: <Avatar
												variant='rounded'
												{...(activeStep >= 1 || activeStep >= 2 || activeStep >= 3 || activeStep >= 4 || activeStep >= 5 && { skin: 'light' })}
												{...(activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5 && { skin: 'filled' })}
												{...(activeStep >= 1 || activeStep >= 2 || activeStep >= 3 || activeStep >= 4 || activeStep >= 5 && { color: 'primary' })}
												sx={{
													mr: '1rem !important',
													...(activeStep === 1 || activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 5 && { boxShadow: theme => theme.shadows[3] }),
													...(activeStep > 1 || activeStep > 2 || activeStep > 3 || activeStep > 4 || activeStep > 5 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
												}}
											>
												<Icon icon={"tabler:file-upload"} />
											</Avatar>}
										<div>
											<Typography className='step-title Mui-active'>{"Upload Documents"}</Typography>
											<Typography variant='caption' className='step-subtitle'>{"Upload Required Documents"}</Typography>
										</div>
									</div>
								</StepLabel>
							</Step>
							<Step key={2} >
								<StepLabel StepIconComponent={StepperCustomDot}>
									<div className='step-label'>
										{activeStep >= 6 ? <CustomAvatar
											variant='rounded'
											{...(activeStep >= 6 && { skin: 'light' })}
											{...(activeStep === 6 && { skin: 'filled' })}
											{...(activeStep >= 6 && { color: 'primary' })}
											sx={{
												mr: '1rem !important',
												...(activeStep === 6 && { boxShadow: theme => theme.shadows[6] }),
												...(activeStep > 6 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
											}}
										>
											<Icon icon={"fa-solid:info"} />
										</CustomAvatar>
											: <Avatar
												variant='rounded'
												{...(activeStep >= 6 && { skin: 'light' })}
												{...(activeStep === 6 && { skin: 'filled' })}
												{...(activeStep >= 6 && { color: 'primary' })}
												sx={{
													mr: '1rem !important',
													...(activeStep === 6 && { boxShadow: theme => theme.shadows[3] }),
													...(activeStep > 6 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
												}}
											>
												<Icon icon={"fa-solid:info"} />
											</Avatar>}
										<div>
											<Typography className='step-title'>{"Personal Info"}</Typography>
											<Typography variant='caption' className='step-subtitle'>{"Add Your Personal Details"}</Typography>
										</div>
									</div>
								</StepLabel>
							</Step>
							<Step key={3} >
								<StepLabel StepIconComponent={StepperCustomDot}>
									<div className='step-label'>
										{activeStep >= 7 ? <CustomAvatar
											variant='rounded'
											{...(activeStep >= 7 && { skin: 'light' })}
											{...(activeStep === 7 && { skin: 'filled' })}
											{...(activeStep >= 7 && { color: 'primary' })}
											sx={{
												mr: '1rem !important',
												...(activeStep === 7 && { boxShadow: theme => theme.shadows[3] }),
												...(activeStep > 7 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.7) })
											}}
										>
											<Icon icon={"arcticons:studysmarter"} />
										</CustomAvatar>
											: <Avatar
												variant='rounded'
												{...(activeStep >= 7 && { skin: 'light' })}
												{...(activeStep === 7 && { skin: 'filled' })}
												{...(activeStep >= 7 && { color: 'primary' })}
												sx={{
													mr: '1rem !important',
													...(activeStep === 7 && { boxShadow: theme => theme.shadows[3] }),
													...(activeStep > 7 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
												}}
											>
												<Icon icon={"arcticons:studysmarter"} />
											</Avatar>}
										<div>
											<Typography className='step-title'>{"Occupation"}</Typography>
											<Typography variant='caption' className='step-subtitle'>{"Add Your Occupation Details"}</Typography>
										</div>
									</div>
								</StepLabel>
							</Step>
							<Step key={4}>
								<StepLabel StepIconComponent={StepperCustomDot}>
									<div className='step-label'>
										{activeStep >= 8 ? <CustomAvatar
											variant='rounded'
											{...(activeStep >= 8 && { skin: 'light' })}
											{...(activeStep === 8 && { skin: 'filled' })}
											{...(activeStep >= 8 && { color: 'primary' })}
											sx={{
												mr: '1rem !important',
												...(activeStep === 8 && { boxShadow: theme => theme.shadows[8] }),
												...(activeStep > 8 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
											}}
										>
											<Icon icon={"tabler:users-group"} />
										</CustomAvatar>
											: <Avatar
												variant='rounded'
												{...(activeStep >= 8 && { skin: 'light' })}
												{...(activeStep === 8 && { skin: 'filled' })}
												{...(activeStep >= 8 && { color: 'primary' })}
												sx={{
													mr: '1rem !important',
													...(activeStep === 8 && { boxShadow: theme => theme.shadows[3] }),
													...(activeStep > 8 && { color: theme => hexToRGBA(theme.palette.primary.main, 0.4) })
												}}
											>
												<Icon icon={"tabler:users-group"} />
											</Avatar>}
										<div>
											<Typography className='step-title'>{"Nomination"}</Typography>
											<Typography variant='caption' className='step-subtitle'>{"Add Your Nomination Details"}</Typography>
										</div>
									</div>
								</StepLabel>
							</Step>
						</Stepper>
					</StepperWrapper>
				</StepperHeaderContainer>
				<Divider sx={{ m: '0 !important' }} />
				<CardContent sx={{ width: '100%' }}>
					{renderContent()}
				</CardContent>
			</Card>
		</>
	)
}

export default StepperCustomVertical
