// import { Image } from "@mui/icons-material"
import * as React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Button, Card, CardContent, Grid, Typography } from "@mui/material"
import mainLogo from '../assets/mainlogo.png';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { APIURL } from '../commonUrl';

const StepFour = ({ loginStep, handleNext, setValues, values, errors, setErrors }) => {
    const [resendDisabled, setResendDisabled] = useState(true);
    const [timeLeft, setTimeLeft] = useState(120);

    useEffect(() => {
        if (resendDisabled) {
            const timer = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);
            setTimeout(() => {
                setResendDisabled(false);
                setTimeLeft(120);
            }, 120000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [resendDisabled]);


    const resendOTP = async () => {
        let data = {
            "mobileno": values.mobileno,
            "type": 'email'
        };

        let config = {
            method: 'post',
            url: APIURL + 'resendOTP',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                var result = response.data
                if (result.status == true) {
                    setErrors({ ...values, successError: 'OTP send successfully!' })
                    // Set the resendDisabled state to true to disable the button
                    setResendDisabled(true);
                    // You can use setTimeout to simulate the delay before the user can resend the OTP code again
                    setTimeout(() => {
                        setResendDisabled(false);
                        setTimeLeft(120);
                    }, 1200000); // Enable the button after 60 seconds
                }

            })
    };



    return (
        <Card className="loginCard">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className='logo'>
                        <img src={mainLogo} alt="fireSpot" height={50} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" color={'GrayText'}>
                            Verify your email
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            We've sent Verification code to your email address : {values.email}. Enter the code from the email in the field below.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='caption'>
                            Type your 6 digit mail Verification code
                        </Typography>
                        <MuiOtpInput
                            value={values.emailotp}
                            onChange={(e) => setValues({ ...values, emailotp: e })}
                            length={6}
                            gap={1}
                            autoFocus
                        />
                        {errors.emailotperror && <Typography variant='caption' color='error'>
                            Please Enter Verification Code!
                        </Typography>}

                    </Grid>
                    <Grid item xs={12}>
                        {resendDisabled && (<Typography variant='body2'>
                            Resend Verification Code in {timeLeft} Seconds
                        </Typography>)}
                    </Grid>
                    {errors.commonError != '' && <Grid item xs={12}>
                        <Typography variant='body2' color={"error"}>
                            {errors.commonError}
                        </Typography>
                    </Grid>}
                    {!resendDisabled && (<Grid item xs={12}>
                        <Button variant="outlined" fullWidth onClick={resendOTP}>Resend OTP</Button>
                    </Grid>)}
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={handleNext}>Continue</Button>
                    </Grid>
                </Grid>
            </CardContent>

        </Card>
    )
}

export default StepFour