import { Box, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { isDesktop } from "react-device-detect"

const IncomeProof = ({ setValues, values, errors }) => {
    return (
        <Box sx={isDesktop && { p: 3 }}>
            <Grid container spacing={1} className="cardtemplate">
                <Grid item xs={12} sm={6}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Type of Income Proof
                        </Typography>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.incomeProofType}
                            // label="Age"
                            onChange={(e) => setValues({ ...values, incomeProofType: e.target.value })}
                            fullWidth
                            size="small"
                            error={errors.incomeProofTypeError}
                            helperText={errors.incomeProofTypeError && "Please Select Income Proof"}
                        >
                            <MenuItem value={"BS"}>Bank Statement For Last 6 Months</MenuItem>
                            <MenuItem value={"ITR"}>Copy Of ITR Acknowledgement</MenuItem>
                            <MenuItem value={"SalarySlip"}>Salary Slip With Stap & Sign</MenuItem>
                            <MenuItem value={"Form16"}>Form 16</MenuItem>
                            <MenuItem value={"CN"}>Certificate Of Networth</MenuItem>
                            <MenuItem value={"Holding"}>Demat Holding Statement</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Upload Income Proof Document
                        </Typography>
                        <TextField
                            type='file'
                            id="outlined-basic"
                            placeholder="Account Number"
                            variant="outlined"
                            fullWidth
                            size='small'
                            name="incomeFile"
                            onChange={e => setValues({ ...values, incomeProofFile: e.target.files })}
                        />
                        <Typography variant='caption' component={'div'}>
                            [Only PDF, JPG & PNG files are allowed]
                        </Typography>
                        <Typography variant='caption' component={'div'} sx={{ fontSize: '10px' }}>
                            [For Bank statements, Please upload document with last 6 months of transactions.]
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default IncomeProof