import { Card, CardContent, Typography } from "@mui/material"
import { Fragment } from "react";
import PaymentIndex from './Payments'
// import TextMobileStepper from "../mobileStepper";

const Payment = ({ setValues, values, errors, setErrors, setLoginStep }) => {
    return (
        <>
            <Card className="stepperCard">
                <CardContent>
                    <Fragment>
                        <Typography variant='h5' align='center' gutterBottom sx={{ color: 'text.secondary', mb: 2 }}>
                            You are just a few steps away to get an account with Pure Broking!
                        </Typography>
                        <Typography variant='body2' sx={{ color: 'text.disabled' }} component='div' align='center' gutterBottom>
                            Keep your Aadhar Card, Pan Card and Bank Account statement with IFSC on it ready.
                        </Typography>
                        <Typography variant='body2' sx={{ color: 'text.disabled' }} component='div' align='center' gutterBottom>
                            Please be real and we promise you, no spamming will be done.
                        </Typography>
                    </Fragment>
                    <PaymentIndex setValues={setValues} values={values} errors={errors} setErrors={setErrors} setLoginStep={setLoginStep} />
                </CardContent>
            </Card>
        </>
    )
}

export default Payment