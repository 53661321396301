// import { Image } from "@mui/icons-material"
import * as React from 'react';
import { Button, Card, CardContent, Grid, TextField, Typography, Stack, Box, CssBaseline, AppBar, Toolbar } from "@mui/material"
import ipoImage from '../assets/ipv_sample_pose.png';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import ImagePreview from './ImagePreview';
import 'react-html5-camera-photo/build/css/index.css';
import { useState } from 'react';
import mainLogo from '../assets/mainlogo.png';
import logo from '../assets/App-icon_1.png';
import CustomAvatar from '../../src/@core/components/mui/avatar'
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';

const KYC = ({ handleNext, setValues, values }) => {
    const [dataUri, setDataUri] = useState('');

  
    function handleTakePhotoAnimationDone(dataUri) {
        console.log('takePhoto', dataUri);
        // setDataUri(dataUri);
        setValues({ ...values, ipvImgCode: dataUri })
    }

    const uploadImg = () => {
        handleNext()
    }

    const isFullscreen = false;
      
    
    return (
        <Card className="kycCapture loginCard">
            <CardContent>
                {isMobile &&
                    <>
                        <CssBaseline />
                        <AppBar component="nav" sx={{ backgroundColor: '#FFF' }}>
                            <Toolbar>
                                <CustomAvatar
                                    variant='rounded'
                                    sx={{
                                        mr: '1rem !important',
                                        boxShadow: theme => theme.shadows[3],
                                    }}
                                    src={logo}
                                >
                                    {/* <Icon icon={steps[activeStep].icon} /> */}
                                </CustomAvatar>
                                <Stack>
                                    <Typography variant='subtitle2' sx={{ textTransform: 'uppercase', color: 'text.secondary' }} component={'div'}>
                                        KYC
                                    </Typography>
                                    {/* <Typography variant='caption' component={'div'} sx={{ color: 'text.secondary' }}>
                            {steps[activeStep].tagline}
                        </Typography> */}
                                </Stack>
                            </Toolbar>
                        </AppBar>
                    </>
                }
                <Box className={'cameraBox'}>
                    <Grid container spacing={1} className="paymentdetail cardtemplate">
                        <Grid item xs={12}>
                            <Typography variant='h5' align='center' gutterBottom sx={{ color: 'text.secondary', mb: 2 }}>
                                Hola! You are about to onboard with Pure.
                            </Typography>
                            <Typography variant='body2' sx={{ color: 'text.disabled' }} component='div' align='center' gutterBottom>
                                Just write the below code {values.ipvRandomNumber} on a piece of paper and hold it in front of the camera
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5} className='kycGrid'>
                            <img src={ipoImage} alt="fireSpot" height={300} />
                            <Typography variant='h4' className='ipvText'>{values.ipvRandomNumber}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} textAlign={'center'}>
                            {
                                (values && values.ipvImgCode)
                                    ?
                                    <>
                                        <ImagePreview dataUri={values.ipvImgCode}
                                            isFullscreen={isFullscreen}
                                        />
                                        <Stack spacing={2} direction="row" justifyContent="center"
                                            alignItems="center">
                                            <Button onClick={() => setValues({ ...values, ipvImgCode: '' })} variant="contained">Recapure</Button>
                                            <Button onClick={uploadImg} variant="contained">Upload</Button>
                                        </Stack>
                                    </>
                                    : <Camera onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                                        isFullscreen={isFullscreen}
                                    />
                            }

                            {/* <Camera
                            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                            onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                            onCameraError={(error) => { handleCameraError(error); }}
                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                            idealResolution={{ width: 300, height: 300 }}
                            imageType={IMAGE_TYPES.JPG}
                            imageCompression={0.97}
                            isMaxResolution={true}
                            isImageMirror={false}
                            isSilentMode={false}
                            isDisplayStartCameraError={true}
                            isFullscreen={false}
                            sizeFactor={0.5}
                            onCameraStart={(stream) => { handleCameraStart(stream); }}
                            onCameraStop={() => { handleCameraStop(); }}
                        /> */}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>

    )
}

export default KYC