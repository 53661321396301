// export const BASEURL = 'http://localhost:3000/'

// export const APIURL = 'http://localhost:14001/'

// export const PAYMENT_RETURN_URL = 'http://localhost:14001/'

// export const PAYMENT_SOCKET_URL = "ws://localhost:14002/"

// export const APPLICATIONSERIES = "A00000000"

// export const resHashKey = "KEY123657234";

// export const GOOGLE_CLIENT_ID = "641199602016-0t6j5p760f7nt3mjqpag05oct1b0p6dj.apps.googleusercontent.com";


export const BASEURL = 'https://testkyc.purebroking.com/'

export const APIURL = 'https://kycservice.purebroking.com/'

export const PAYMENT_RETURN_URL = 'https://kycservice.purebroking.com/'

export const PAYMENT_SOCKET_URL = "wss://wss.purebroking.com/"

export const APPLICATIONSERIES = "A00000000"

export const resHashKey = "KEY123657234";

export const GOOGLE_CLIENT_ID = "641199602016-0t6j5p760f7nt3mjqpag05oct1b0p6dj.apps.googleusercontent.com";
