import { Box, Button, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

const StepPersonalDetails = (props) => {
	const { steps, activeStep, handleNext, handleBack } = props
	// console.log(activeStep)
	
	return (
		<Box sx={{
			position: 'absolute',
			bottom: '10px',
			width: '75%'
		}}>
			<Grid container spacing={5}>
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button variant='tonal' color='secondary' disabled={activeStep === 0 || activeStep === 1} onClick={handleBack}>
						Back
					</Button>
					{
						activeStep == 0 ?
							<Button variant='contained' onClick={handleNext}>
								Pay & Continue
							</Button>
							:
							<Button variant='contained' onClick={handleNext}>
								{activeStep === steps.length - 1 ? 'Submit' : 'Next'}
							</Button>
					}
				</Grid>
			</Grid>
		</Box>
	)
}

export default StepPersonalDetails
