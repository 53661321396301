import { PAYMENT_SOCKET_URL } from "./commonUrl";

// import { pureTradeUrl } from "../Const";
export const join = (token) => {
    if (global.as && global.as.readyState == WebSocket.OPEN) {        
        global.as.send(JSON.stringify(
            { join: token }
        ));
    }
}


export const connectPaymentSocket = () => {
    global.as = new WebSocket(PAYMENT_SOCKET_URL);
    global.as.binaryType = 'arraybuffer';

    global.as.onopen = () => {
        console.log('Payment Socket Connected!');        
    }

    global.as.onclose = () => {
        global.as = null
        console.log("Payment Socket DisConnected!");
        setTimeout(() => {
            connectPaymentSocket()
        }, 2000)
    };

}


