import { Box, Grid, Button, Stack, Typography } from "@mui/material"
import { isDesktop, isMobile } from "react-device-detect"
import Slim from '../slim/slim.react';
import { useRef } from "react";
import SignaturePad from 'react-signature-canvas'

const Signature = ({ setValues, values, errors }) => {
    const inputElPhoto = useRef(null);
    const signatureCanvasRef = useRef(null);

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
        };
    }
    const getData = async () => {

        if (inputElPhoto.current != null) {
            // console.log(inputElPhoto);
            // console.log(inputElPhoto.current.files);
            if (inputElPhoto.current.nextElementSibling.value != '') {
                var Photobase64 = JSON.parse(inputElPhoto.current.nextElementSibling.value).output.image
                var Photoextensions = JSON.parse(inputElPhoto.current.nextElementSibling.value).output.type                
            } else {
                await getBase64(inputElPhoto.current.files[0], async (result) => {
                    setValues({ ...values, signExtension: inputElPhoto.current.files[0].type, signBase64: result })
                })
            }
        }
    }
   
    function slimService(formdata, progress, success, failure, slim) {
        // slim instance reference
        console.log(slim)

        // form data to post to server
        // set serviceFormat to "file" to receive an array of files
        // console.log(formdata)

        // call these methods to handle upload state
        // console.log(progress, success, failure)
    }

    const clearSignature = () => {
        setValues({ ...values, signCanvasExtension: "", signCanvasBase64: "" })
        setTimeout(() => {
            signatureCanvasRef.current.clear();
        }, 1000);
    };

    const saveSignature = () => {
        const signatureImage = signatureCanvasRef.current.toDataURL();
        const type = signatureImage.split(';')[0].split(':')[1];
        // console.log('Signature Image:', signatureImage);
        // console.log('Signature type:', type);

        setValues({ ...values, signCanvasExtension: type, signCanvasBase64: signatureImage })
    };

    return (
        <>
            <Box sx={isDesktop && { p: 3 }}>
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ p: 1 }} >
                            {/* service={slimService} slimInit={slimInit} */}
                            <Slim ratio="7:3" service={slimService}>
                                <input type='file' ref={inputElPhoto} id='Photo' onChange={getData} />
                            </Slim>
                        </Box>
                    </Grid>

                    {isMobile && values.signBase64 == "" && <Grid item xs={12} sm={4}>
                        <Typography textAlign={'center'} component={'div'} color={'text.secondary'}>OR</Typography>
                        <Box sx={{ p: 1, border: "1px solid", mt: 1 }} >

                            {values.signCanvasBase64
                                ? <img
                                    src={values.signCanvasBase64} />
                                : <SignaturePad
                                    canvasProps={{ height: 200, width: 'auto' }}
                                    ref={signatureCanvasRef}
                                />}
                            <Stack
                                direction='row'
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                            >
                                <Button variant="outlined" onClick={clearSignature}>Clear</Button>
                                <Button variant="contained" onClick={saveSignature}>Save</Button>
                            </Stack>
                        </Box>
                    </Grid>}
                    {errors.signError && <Grid item xs={12}>
                        <Typography variant='body2' color={"error"}>
                            Please Select Signature Image
                        </Typography>
                    </Grid>}
                </Grid>
            </Box>
        </>
    )
}

export default Signature