import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material"
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import mainLogo from '../assets/mainlogo.png';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { GOOGLE_CLIENT_ID } from "../commonUrl";

const Login = ({ setGoogleLogin, handleNext, setValues, values, errors }) => {
    useGoogleOneTapLogin({
        onError: error => console.log(error),
        onSuccess: (response) => {
            console.log(response)
            global.myCache.set('GoogleData', JSON.stringify(response), 0)
            setValues({
                ...values,
                googleEmail: response.email,
                isGoogleEmail: 1
            })
            setTimeout(() => {
                handleNext()
            }, 1000);
        },
        googleAccountConfigs: {
            client_id: GOOGLE_CLIENT_ID
        }
    });
    // const [user, setUser] = useState();

    const onLogin = useGoogleLogin({
        onSuccess: (codeResponse) => (setGoogleLogin(codeResponse)),
        onError: (error) => console.log('Login Failed:', error)
    });



    // useEffect(() => {

    // }, []);

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        // setProfile(null);
    };

    return (
        <Card className="loginCard">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className='logo'>
                        <img src={mainLogo} alt="Logo" height={50} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" color={'GrayText'}>
                            Welcome to Pure Broking!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            Please sign-up or continue your incomplete application
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={onLogin}>Continue with Google</Button>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <Typography variant='body'>
                            OR
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            type='email'
                            label="Your Email Address"
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                            error={errors.emailerror}
                            size='small'
                            value={values.email}
                            onChange={(e) => setValues({ ...values, email: e.target.value })}
                            helperText={errors.emailerror && "Please Enter Email / Please Enter Valid Email"}
                        />
                    </Grid>
                    {errors.commonError != '' && <Grid item xs={12}>
                        <Typography variant='body2' color={"error"}>
                            {errors.commonError}
                        </Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        {/* <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log(credentialResponse);
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            auto_select={true}
                        /> */}
                        <Button variant="contained" fullWidth onClick={handleNext}>Continue</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Login