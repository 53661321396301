// import { Image } from "@mui/icons-material"
import * as React from 'react';
import { Box, Button, Card, CardContent, Grid, Stack, TextField, Typography } from "@mui/material"
import mainLogo from '../assets/mainlogo.png';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';

const ConfirmDetails = ({ handleNext, values, setLoginStep }) => {

    const RestartApp = () => {
        setLoginStep(5)
        var userData = JSON.parse(localStorage.getItem('EKYCData'))
        userData.appStep = 6
        localStorage.setItem('EKYCData', JSON.stringify(userData))
    }


    return (
        <Card className="loginCard">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className='logo'>
                        <img src={mainLogo} alt="Logo" height={50} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' align='center'>
                            Please sign-up or continue your incomplete application
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Swiper pagination={true} modules={[Pagination]} className="mySwiper swiperHeight">
                            <SwiperSlide>
                                <Card variant="outlined" sx={{ minHeight: '360px' }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography variant='h6'>
                                            Basic Info
                                        </Typography>
                                        <Grid container spacing={1} sx={{ pt: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Name
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.pancardname}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Father/Spouse Name
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.piFatherFirstName + " " + values.piFatherMiddleName + " " + values.piFatherLastName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Mother Name
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.piMotherFirstName + " " + values.piMotherMiddleName + " " + values.piMotherLastName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card variant="outlined" sx={{ minHeight: '360px' }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography variant='h6'>
                                            Correspondence Address
                                        </Typography>
                                        <Grid container spacing={1} sx={{ pt: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Address 1
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.aadharcardAddressOne}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Address 2
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.aadharcardAddressTwo}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    City
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.aadharcardCity}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    State
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.aadharcardState}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card variant="outlined" sx={{ minHeight: '360px' }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography variant='h6'>
                                            Permanent Address
                                        </Typography>
                                        <Grid container spacing={1} sx={{ pt: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Address 1
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.permanentAddressOne}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Address 2
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.permanentAddressTwo}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    City
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.permanentCity}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    State
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.permanentState}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card variant="outlined" sx={{ minHeight: '360px' }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography variant='h6'>
                                            PAN Info
                                        </Typography>
                                        <Grid container spacing={1} sx={{ pt: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    PAN No.
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.pancardnumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Name as per PAN
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.pancardname}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    DOB
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.pancarddob}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Father/Spouse Name as per PAN
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.pancardfathername}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card variant="outlined" sx={{ minHeight: '360px' }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography variant='h6'>
                                            Bank Info
                                        </Typography>
                                        <Grid container spacing={1} sx={{ pt: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Bank Account No
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.accountNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    IFSC
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.ifscCode}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Account Type
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.accountType}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    MICR
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.bankMicr}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card variant="outlined" sx={{ minHeight: '360px' }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography variant='h6'>
                                            Other Personal Info
                                        </Typography>
                                        <Grid container spacing={1} sx={{ pt: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Gender
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.piGender}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Marital Status
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.piMaritalStatus}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Politically Affilliated
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.piPolitical == 0 ? "NO" : "YES"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card variant="outlined" sx={{ minHeight: '360px' }}>
                                    <CardContent sx={{ p: 1 }}>
                                        <Typography variant='h6'>
                                            Other Info
                                        </Typography>
                                        <Grid container spacing={1} sx={{ pt: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Occupation
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.occupation}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Annual Income
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.annualIncome}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Trading Experience
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.longTrading}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle' sx={{ fontSize: '14px' }} component={'div'}>
                                                    Tax Residence Status
                                                </Typography>
                                                <Typography variant='button'>
                                                    {values.piMaritalStatus}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                        </Swiper>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                            <Button variant='contained' size='small' onClick={RestartApp}>
                                Restart Application
                            </Button>
                            <Button variant='contained' color="success" size='small' onClick={handleNext}>
                                Confirm Application
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ConfirmDetails