import { CheckBox } from "@mui/icons-material"
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Select, Switch, Typography, TextField } from "@mui/material"
import { useState } from "react"

const Occupation = ({ setValues, values, errors }) => {
    const [display, setDisplay] = useState(false)
    const handleReferral = () => {
        setDisplay(!display)
        setValues({ ...values, isReferral: !values.isReferral })
    }

    return (
        <Box>
            <Grid container spacing={1} className="paymentdetail cardtemplate">
                <Grid item xs={12}>
                    <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                        Occupation
                    </Typography>
                    <Typography variant='caption' sx={{ color: 'text.disabled' }} component='div' gutterBottom>
                        Tell us about your occupation and experience with trading.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Your Occupation
                    </Typography>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.occupation}
                        onChange={(e) => setValues({ ...values, occupation: e.target.value })}
                        error={errors.occupationError}
                        helperText={errors.occupationError && "Please Select Occupation "}
                        fullWidth
                        size="small"
                    >
                        <MenuItem value="01">Private Sector Service</MenuItem>
                        <MenuItem value="02">Public Sector</MenuItem>
                        <MenuItem value="03">Business</MenuItem>
                        <MenuItem value="04">Professional</MenuItem>
                        <MenuItem value="05">Agriculturist</MenuItem>
                        <MenuItem value="06">Retired</MenuItem>
                        <MenuItem value="07">Housewife</MenuItem>
                        <MenuItem value="08">Student</MenuItem>
                        <MenuItem value="09">Forex Dealer</MenuItem>
                        <MenuItem value="10">Government Service</MenuItem>
                        <MenuItem value="99">Others</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Annual Income
                    </Typography>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.annualIncome}
                        onChange={(e) => setValues({ ...values, annualIncome: e.target.value })}
                        error={errors.annualIncomeError}
                        helperText={errors.annualIncomeError && "Please Select Annual Income "}
                        fullWidth
                        size="small"
                    >
                        <MenuItem value="01">Less Than One Lakhs</MenuItem>
                        <MenuItem value="02">One to Five Lakhs</MenuItem>
                        <MenuItem value="03">Five to Ten Lakhs</MenuItem>
                        <MenuItem value="04">Ten to TwentyFive Lakhs</MenuItem>
                        <MenuItem value="05">Above TwentyFive Lakhs</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        How long have you been trading?
                    </Typography>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.longTrading}
                        onChange={(e) => setValues({ ...values, longTrading: e.target.value })}
                        error={errors.longTradingError}
                        helperText={errors.longTradingError && "Please Select Trading experience "}
                        fullWidth
                        size="small"
                    >
                        <MenuItem value="00">No Experience</MenuItem>
                        <MenuItem value="01">Below 1 Year</MenuItem>
                        <MenuItem value="02">1-2 Year</MenuItem>
                        <MenuItem value="03">2-4 Year</MenuItem>
                        <MenuItem value="04">4-10 Year</MenuItem>
                        <MenuItem value="05">10 Year</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Delivery Instruction Slip
                    </Typography>
                    <FormControlLabel control={<Checkbox checked={values.isDelivarySlip} onChange={(e) => setValues({ ...values, isDelivarySlip: !values.isDelivarySlip })} />} />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Have you been referred by someone you know?
                    </Typography>
                    <FormControlLabel control={<Switch onChange={handleReferral} />} />
                </Grid>
                {display && <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Referral Code
                    </Typography>
                    <TextField
                        type="text"
                        id="outlined-basic"
                        // placeholder="Date of Birth"
                        variant="outlined"
                        fullWidth
                        size='small'
                        error={errors.referralNameError}
                        value={values.referralName}
                        onChange={(e) => setValues({ ...values, referralName: e.target.value })}
                        helperText={errors.referralNameError && "Please Enter Referral Code "}
                    />
                </Grid>} */}
            </Grid>
        </Box>
    )
}

export default Occupation