// import { Image } from "@mui/icons-material"
import * as React from 'react';
import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material"
import mainLogo from '../assets/mainlogo.png';

const StepFive = ({ loginStep, handleNext, setValues, values, errors }) => {
    return (
        <Card className="loginCard">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className='logo'>
                        <img src={mainLogo} alt="fireSpot" height={50} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" color={'GrayText'}>
                            Congratulations!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            You have alloted default Login Code : <strong>{values.clientcode != "" ? values.clientcode : 'AAAAA'}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '15px' }}>
                        <Typography variant='body2'>
                            Want to select your own login code?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '15px' }}>
                        <Typography variant='body2'>
                            Type your 5 digit Client Code below
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                            size='small'
                            onChange={(e) => setValues({ ...values, textClientCode: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <Typography variant='body'>
                            OR
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={handleNext}>Continue</Button>
                    </Grid>
                    {errors.commonError != '' && <Grid item xs={12}>
                        <Typography variant='body2' color={"error"}>
                            {errors.commonError}
                        </Typography>
                    </Grid>}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default StepFive