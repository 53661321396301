import { Add, Close, Delete } from "@mui/icons-material";
import { Box, Container, FormControlLabel, Grid, MenuItem, Select, Switch, TextField, Typography, Stack, Divider, FormControl, Checkbox } from "@mui/material"
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { jsxDEV } from "react/jsx-dev-runtime";

const Nomination = ({ setValues, values, errors }) => {

    const [display, setDisplay] = useState({
        first: false,
        second: false,
        third: false
    })
    const [formValues, setFormValues] = useState({
        first: true,
        second: false,
        third: false
    })
    //https://sadam-bapunawar.medium.com/add-and-remove-form-fields-dynamically-using-react-and-react-hooks-3b033c3c0bf5

    let addFormFields = () => {
        var share = parseInt(values.nomineeOneShare) + parseInt(values.nomineeTwoShare) + parseInt(values.nomineeThreeShare)
        if (parseInt(share) < 100) {
            if (!formValues.second) {
                setFormValues({ ...formValues, second: true })
            }
            else if (!formValues.third) {
                setFormValues({ ...formValues, third: true })
            }
        }
    }

    let removeFormFields = (i) => {
        if (i == 2) {
            setFormValues({ ...formValues, second: false })
        }
        else if (i == 3) {
            setFormValues({ ...formValues, third: false })
        }
    }

    const handleReferral = () => {
        setValues({ ...values, isNominee: !values.isNominee })
    }

    function calculateAge(date) {
        var formattedDate = date.split("/")
        var birthdateTimeStamp = new Date(formattedDate[2], formattedDate[1], formattedDate[0])
        var currentDate = new Date().getTime();
        var difference = currentDate - birthdateTimeStamp;
        var currentAge = Math.floor(difference / 31557600000)
        // dividing by 1000*60*60*24*365.25
        return currentAge
    }

    /* const handleDoB = (val, type) => {
        if (type == 'one') {
            setValues({ ...values, nomineeOneDOB: val })
            if (calculateAge(moment(val).format('DD/MM/YYYY')) < 18) {
                setDisplay({ ...display, first: true })
            } else {
                setDisplay({ ...display, first: false })
            }
        } else if (type == 'two') {
            setValues({ ...values, nomineeTwoDOB: val })
            if (calculateAge(moment(val).format('DD/MM/YYYY')) < 18) {
                console.log("inn");
                setDisplay({ ...display, second: true })
            } else {
                setDisplay({ ...display, second: false })
            }
        } else if (type == 'three') {
            setValues({ ...values, nomineeThreeDOB: val })
            if (calculateAge(moment(val).format('DD/MM/YYYY')) < 18) {
                console.log("inn");
                setDisplay({ ...display, third: true })
            } else {
                setDisplay({ ...display, third: false })
            }
        }
    } */

    useEffect(() => {
        if (values.nomineeOneDOB && values.nomineeOneDOB != "") {
            if (calculateAge(moment(values.nomineeOneDOB).format('DD/MM/YYYY')) < 18) {
                setDisplay({ ...display, first: true })
            } else {
                setDisplay({ ...display, first: false })
            }
        }
    }, [values.nomineeOneDOB])

    useEffect(() => {
        if (values.nomineeTwoDOB != "") {
            if (calculateAge(moment(values.nomineeTwoDOB).format('DD/MM/YYYY')) < 18) {
                setDisplay({ ...display, two: true })
            } else {
                setDisplay({ ...display, two: false })
            }
        }
    }, [values.nomineeTwoDOB])

    useEffect(() => {
        if (values.nomineeThreeDOB != "") {
            if (calculateAge(moment(values.nomineeThreeDOB).format('DD/MM/YYYY')) < 18) {
                setDisplay({ ...display, three: true })
            } else {
                setDisplay({ ...display, three: false })
            }
        }
    }, [values.nomineeThreeDOB])



    return (
        <Grid container spacing={1} className="paymentdetail cardtemplate">
            {isDesktop && <Grid item xs={12}>
                <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                    Nomination
                </Typography>
                <Typography variant='caption' sx={{ color: 'text.disabled' }} component='div' gutterBottom>
                    Add your account nomination details.
                </Typography>
            </Grid>}
            <Grid item xs={12} sm={12}>
                <Typography variant='body2'>
                    Do you wish to nominate anyone for this account?
                </Typography>
                <FormControlLabel control={<Switch checked={values.isNominee} onChange={handleReferral} />} />
            </Grid>
            {values.isNominee == true && <Box sx={{ p: 1 }}>
                {formValues.first && <Grid item xs={12} sm={12} sx={{ mb: '70px' }}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant='h6' sx={{ color: 'text.secondary' }}>
                            First Nominee
                        </Typography>
                        <IconButton aria-label="delete" sx={{ position: 'absolute', right: '20px' }} onClick={addFormFields}>
                            <Add />
                        </IconButton>
                    </Stack>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='body2'>
                                Nominee Name 1
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeOneName}
                                onChange={(e) => setValues({ ...values, nomineeOneName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Relationship
                            </Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.nomineeOneRelation}
                                onChange={(e) => setValues({ ...values, nomineeOneRelation: e.target.value })}
                                fullWidth
                                size="small"
                            >
                                <MenuItem value="Brother">Brother</MenuItem>
                                <MenuItem value="Spouce">Spouce</MenuItem>
                                <MenuItem value="Sister">Sister</MenuItem>
                                <MenuItem value="Father">Father</MenuItem>
                                <MenuItem value="Mother">Mother</MenuItem>
                                <MenuItem value="Son">Son</MenuItem>
                                <MenuItem value="Daughter">Daughter</MenuItem>
                                <MenuItem value="Uncle">Uncle</MenuItem>
                                <MenuItem value="Grand parent">Grand parent</MenuItem>
                                <MenuItem value="Aunt">Aunt</MenuItem>
                                <MenuItem value="other">Friend</MenuItem>
                                <MenuItem value="other">other Relation</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant='body2'>
                                Share
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeOneShare}
                                onChange={(e) => setValues({ ...values, nomineeOneShare: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Mobile
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeOneMobile}
                                onChange={(e) => setValues({ ...values, nomineeOneMobile: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                ID Proof
                            </Typography>
                            <TextField
                                type="file"
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                onChange={e => setValues({ ...values, nomineeOneIdProof: e.target.files })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Date of Birth of Nominee
                            </Typography>
                            <TextField
                                type="date"
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth size='small'
                                value={values.nomineeOneDOB}
                                onChange={(e) => setValues({ ...values, nomineeOneDOB: e.target.value })} // setValues({ ...values, nomineeOneDOB: e.target.value })
                            />
                        </Grid>
                    </Grid>
                    {display.first &&
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3}>
                                <Typography variant='body2'>
                                    Relationship with Guardian
                                </Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.nomineeOneRelationshipGuardian}
                                    onChange={(e) => setValues({ ...values, nomineeOneRelationshipGuardian: e.target.value })}
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value="Brother">Brother</MenuItem>
                                    <MenuItem value="Spouce">Spouce</MenuItem>
                                    <MenuItem value="Sister">Sister</MenuItem>
                                    <MenuItem value="Father">Father</MenuItem>
                                    <MenuItem value="Mother">Mother</MenuItem>
                                    <MenuItem value="Son">Son</MenuItem>
                                    <MenuItem value="Daughter">Daughter</MenuItem>
                                    <MenuItem value="Uncle">Uncle</MenuItem>
                                    <MenuItem value="Grand parent">Grand parent</MenuItem>
                                    <MenuItem value="Aunt">Aunt</MenuItem>
                                    <MenuItem value="other">Friend</MenuItem>
                                    <MenuItem value="other">other Relation</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant='body2'>
                                    Name of the Guardian
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    value={values.nomineeOneGuardianName}
                                    onChange={(e) => setValues({ ...values, nomineeOneGuardianName: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant='body2'>
                                    Mobile
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    value={values.nomineeOneGuardianMobile}
                                    onChange={(e) => setValues({ ...values, nomineeOneGuardianMobile: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant='body2'>
                                    ID Proof
                                </Typography>
                                <TextField
                                    type="file"
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    onChange={e => setValues({ ...values, nomineeOneGuardianIdProof: e.target.files })}
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>}
                {formValues.second && <Grid item xs={12} sm={12} sx={{ mb: '70px' }}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant='h6' sx={{ color: 'text.secondary' }}>
                            Second Nominee
                        </Typography>
                        <IconButton aria-label="delete" sx={{ position: 'absolute', right: '20px' }} onClick={(e) => removeFormFields(2)}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='body2'>
                                Nominee Name 2
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeTwoName}
                                onChange={(e) => setValues({ ...values, nomineeTwoName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Relationship
                            </Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.nomineeTwoRelation}
                                onChange={(e) => setValues({ ...values, nomineeTwoRelation: e.target.value })}
                                fullWidth
                                size="small"
                            >
                                <MenuItem value="Brother">Brother</MenuItem>
                                <MenuItem value="Spouce">Spouce</MenuItem>
                                <MenuItem value="Sister">Sister</MenuItem>
                                <MenuItem value="Father">Father</MenuItem>
                                <MenuItem value="Mother">Mother</MenuItem>
                                <MenuItem value="Son">Son</MenuItem>
                                <MenuItem value="Daughter">Daughter</MenuItem>
                                <MenuItem value="Uncle">Uncle</MenuItem>
                                <MenuItem value="Grand parent">Grand parent</MenuItem>
                                <MenuItem value="Aunt">Aunt</MenuItem>
                                <MenuItem value="other">Friend</MenuItem>
                                <MenuItem value="other">other Relation</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant='body2'>
                                Share
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeThreeShare}
                                onChange={(e) => setValues({ ...values, nomineeThreeShare: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Mobile
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeThreeMobile}
                                onChange={(e) => setValues({ ...values, nomineeThreeMobile: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                ID Proof
                            </Typography>
                            <TextField type="file" id="outlined-basic" variant="outlined" fullWidth size='small' />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Date of Birth of Nominee
                            </Typography>
                            <TextField
                                type="date"
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth size='small'
                                value={values.nomineeThreeDOB}
                                onChange={(e) => setValues({ ...values, nomineeThreeDOB: e.target.value })}
                            />
                        </Grid>
                        {display.second &&
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        Relationship with Guardian
                                    </Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.nomineeTwoRelationshipGuardian}
                                        onChange={(e) => setValues({ ...values, nomineeTwoRelationshipGuardian: e.target.value })}
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem value="Brother">Brother</MenuItem>
                                        <MenuItem value="Spouce">Spouce</MenuItem>
                                        <MenuItem value="Sister">Sister</MenuItem>
                                        <MenuItem value="Father">Father</MenuItem>
                                        <MenuItem value="Mother">Mother</MenuItem>
                                        <MenuItem value="Son">Son</MenuItem>
                                        <MenuItem value="Daughter">Daughter</MenuItem>
                                        <MenuItem value="Uncle">Uncle</MenuItem>
                                        <MenuItem value="Grand parent">Grand parent</MenuItem>
                                        <MenuItem value="Aunt">Aunt</MenuItem>
                                        <MenuItem value="other">Friend</MenuItem>
                                        <MenuItem value="other">other Relation</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        Name of the Guardian
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        value={values.nomineeThreeGuardianName}
                                        onChange={(e) => setValues({ ...values, nomineeThreeGuardianName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        Mobile
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        value={values.nomineeThreeGuardianMobile}
                                        onChange={(e) => setValues({ ...values, nomineeThreeGuardianMobile: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        ID Proof
                                    </Typography>
                                    <TextField
                                        type="file"
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        onChange={e => setValues({ ...values, nomineeOneGuardianIdProof: e.target.files })}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>}
                {formValues.third && <Grid item xs={12} sm={12} sx={{ mb: '70px' }}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant='h6' sx={{ color: 'text.secondary' }}>
                            Third Nominee
                        </Typography>
                        <IconButton aria-label="delete" sx={{ position: 'absolute', right: '20px' }} onClick={(e) => removeFormFields(3)}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant='body2'>
                                Nominee Name 3
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeThreeName}
                                onChange={(e) => setValues({ ...values, nomineeThreeName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Relationship
                            </Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.nomineeThreeRelation}
                                onChange={(e) => setValues({ ...values, nomineeThreeRelation: e.target.value })}
                                fullWidth
                                size="small"
                            >
                                <MenuItem value="Brother">Brother</MenuItem>
                                <MenuItem value="Spouce">Spouce</MenuItem>
                                <MenuItem value="Sister">Sister</MenuItem>
                                <MenuItem value="Father">Father</MenuItem>
                                <MenuItem value="Mother">Mother</MenuItem>
                                <MenuItem value="Son">Son</MenuItem>
                                <MenuItem value="Daughter">Daughter</MenuItem>
                                <MenuItem value="Uncle">Uncle</MenuItem>
                                <MenuItem value="Grand parent">Grand parent</MenuItem>
                                <MenuItem value="Aunt">Aunt</MenuItem>
                                <MenuItem value="other">Friend</MenuItem>
                                <MenuItem value="other">other Relation</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant='body2'>
                                Share
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeThreeShare}
                                onChange={(e) => setValues({ ...values, nomineeThreeShare: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Mobile
                            </Typography>
                            <TextField
                                type="number"
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={values.nomineeThreeMobile}
                                onChange={(e) => setValues({ ...values, nomineeThreeMobile: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                ID Proof
                            </Typography>
                            <TextField
                                type="file"
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                size='small'
                                onChange={e => setValues({ ...values, nomineeThreeIdProof: e.target.files })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2'>
                                Date of Birth of Nominee
                            </Typography>
                            <TextField
                                type="date"
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth size='small'
                                value={values.nomineeThreeDOB}
                                onChange={(e) => setValues({ ...values, nomineeThreeDOB: e.target.value })}
                            />
                        </Grid>
                        {display.third &&
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        Relationship with Guardian
                                    </Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.nomineeThreeRelationshipGuardian}
                                        onChange={(e) => setValues({ ...values, nomineeThreeRelationshipGuardian: e.target.value })}
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem value="Brother">Brother</MenuItem>
                                        <MenuItem value="Spouce">Spouce</MenuItem>
                                        <MenuItem value="Sister">Sister</MenuItem>
                                        <MenuItem value="Father">Father</MenuItem>
                                        <MenuItem value="Mother">Mother</MenuItem>
                                        <MenuItem value="Son">Son</MenuItem>
                                        <MenuItem value="Daughter">Daughter</MenuItem>
                                        <MenuItem value="Uncle">Uncle</MenuItem>
                                        <MenuItem value="Grand parent">Grand parent</MenuItem>
                                        <MenuItem value="Aunt">Aunt</MenuItem>
                                        <MenuItem value="other">Friend</MenuItem>
                                        <MenuItem value="other">other Relation</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        Name of the Guardian
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        value={values.nomineeThreeGuardianName}
                                        onChange={(e) => setValues({ ...values, nomineeThreeGuardianName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        Mobile
                                    </Typography>
                                    <TextField
                                        type="number"
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        value={values.nomineeThreeGuardianMobile}
                                        onChange={(e) => setValues({ ...values, nomineeThreeGuardianMobile: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant='body2'>
                                        ID Proof
                                    </Typography>
                                    <TextField
                                        type="file"
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        onChange={e => setValues({ ...values, nomineeThreeGuardianIdProof: e.target.files })}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>}
            </Box>}

            <Divider sx={{ width: '100%' }} />
            <FormControl fullWidth>
                <FormControlLabel
                    style={{ display: 'table' }}
                    control={<div style={{ display: 'table-cell' }}><Checkbox /></div>}
                    label={<div className="disclaimerandnotes">I have read and understood the contents of the "Rights and Obligation's Docmunent", "Risk Disclosure Document", "Guidance Note" & "Terms and Conditions" availabe on www.purebroking.com and agree to abide by and to be bound by the rules as are in force from time to time for such accounts</div>}
                />
            </FormControl>
            <FormControl fullWidth>
                <FormControlLabel
                    style={{ display: 'table' }}
                    control={<div style={{ display: 'table-cell' }}><Checkbox /></div>}
                    label={<div className="disclaimerandnotes">I wish to open Demat and trading account with pure broking pvt.ltd and also authorise to redirect me to NSDL site to sign account opening form.</div>}
                />
            </FormControl>
            {/* <div className="button-section">
                    <button className="button add" type="button" onClick={() => addFormFields()}>Add</button>
                    <button className="button submit" type="submit">Submit</button>
                </div> */}
        </Grid >
    )
}

export default Nomination