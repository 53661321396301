export const stateObj = {
    "001": "Jammu and Kashmir",
    "002": "Himachal Pradesh",
    "003": "Punjab",
    "004": "Chandigarh",
    "005": "Uttarakhand",
    "006": "Haryana",
    "007": "Delhi",
    "008": "Rajasthan",
    "009": "Uttar Pradesh",
    "010": "Bihar",
    "011": "Sikkim",
    "012": "Arunachal Pradesh",
    "013": "Assam",
    "014": "Manipur",
    "015": "Mizoram",
    "016": "Tripura",
    "017": "Meghalaya",
    "018": "Nagaland",
    "019": "West Bengal",
    "020": "Jharkhand",
    "021": "Orissa",
    "022": "Chhattisgarh",
    "023": "Madhya Pradesh",
    "024": "Gujarat",
    "025": "Daman and Diu",
    "026": "Dadra and Nagar Haveli",
    "027": "Maharashtra",
    "028": "Andhra Pradesh",
    "029": "Karnataka",
    "030": "Goa",
    "031": "Lakshadweep",
    "032": "Kerala",
    "033": "Tamil Nadu",
    "034": "Puducherry",
    "035": "Andaman and Nicobar Islands",
    "036": "APO",
    "037": "Telangana",
}

export const GenderObj = {
    "M": "Male",
    "F": "Female",
}

export const Occupation = {
    "01": "PRIVATE SECTOR SERVICE",
    "02": "PUBLIC SECTOR",
    "03": "BUSINESS",
    "04": "PROFESSIONAL",
    "05": "AGRICULTURIST",
    "06": "RETIRED",
    "07": "HOUSEWIFE",
    "08": "STUDENT",
    "09": "FOREX DEALER",
    "10": "GOVERNMENT SERVICE",
    "11": "PUBLIC SECTOR / GOVERNMENT SERVICE",
    "99": "OTHERS",
}

export const MaritalStatus = {
    "01": "married",
    "02": "single",
}

export const CountryMaster = {
    "001": "Afghanistan",
    "002": "Aland Islands",
    "003": "Albania",
    "004": "Algeria",
    "005": "American Samoa",
    "006": "Andorra",
    "007": "Angola",
    "008": "Anguilla",
    "009": "Antarctica",
    "010": "Antigua And Barbuda",
    "011": "Argentina",
    "012": "Armenia",
    "013": "Aruba",
    "014": "Australia",
    "015": "Austria",
    "016": "Azerbaijan",
    "017": "Bahamas",
    "018": "Bahrain",
    "019": "Bangladesh",
    "020": "Barbados",
    "021": "Belarus",
    "022": "Belgium",
    "023": "Belize",
    "024": "Benin",
    "025": "Bermuda",
    "026": "Bhutan",
    "027": "Bolivia",
    "028": "Bosnia And Herzegovina",
    "029": "Botswana",
    "030": "Bouvet Island",
    "031": "Brazil",
    "032": "British Indian Ocean Territory",
    "033": "Brunei Darussalam",
    "034": "Bulgaria",
    "035": "Burkina Faso",
    "036": "Burundi",
    "037": "Cambodia",
    "038": "Cameroon",
    "039": "Canada",
    "040": "Cape Verde",
    "041": "Cayman Islands",
    "042": "Central African Republic",
    "043": "Chad",
    "044": "Chile",
    "045": "China",
    "046": "Christmas Island",
    "047": "Cocos (Keeling) Islands",
    "048": "Colombia",
    "049": "Comoros",
    "050": "Congo",
    "051": "Congo, The Democratic Republic Of The",
    "052": "Cook Islands",
    "053": "Costa Rica",
    "054": "Cote D'Ivoire",
    "055": "Croatia",
    "056": "Cuba",
    "057": "Cyprus",
    "058": "Czech Republic",
    "059": "Denmark",
    "060": "Djibouti",
    "061": "Dominica",
    "062": "Dominican Republic",
    "063": "Ecuador",
    "064": "Egypt",
    "065": "El Salvador",
    "066": "Equatorial Guinea",
    "067": "Eritrea",
    "068": "Estonia",
    "069": "Ethiopia",
    "070": "Falkland Islands (Malvinas)",
    "071": "Faroe Islands",
    "072": "Fiji",
    "073": "Finland",
    "074": "France",
    "075": "French Guiana",
    "076": "French Polynesia",
    "077": "French Southern Territories",
    "078": "Gabon",
    "079": "Gambia",
    "080": "Georgia",
    "081": "Germany",
    "082": "Ghana",
    "083": "Gibraltar",
    "084": "Greece",
    "085": "Greenland",
    "086": "Grenada",
    "087": "Guadeloupe",
    "088": "Guam",
    "089": "Guatemala",
    "090": "Guernsey",
    "091": "Guinea",
    "092": "Guinea-Bissau",
    "093": "Guyana",
    "094": "Haiti",
    "095": "Heard Island And Mcdonald Islands",
    "096": "Holy See (Vatican City State)",
    "097": "Honduras",
    "098": "Hong Kong",
    "099": "Hungary",
    "100": "Iceland",
    "101": "India",
    "102": "Indonesia",
    "103": "Iran, Islamic Republic Of",
    "104": "Iraq",
    "105": "Ireland",
    "106": "Isle Of Man",
    "107": "Israel",
    "108": "Italy",
    "109": "Jamaica",
    "110": "Japan",
    "111": "Jersey",
    "112": "Jordan",
    "113": "Kazakhstan",
    "114": "Kenya",
    "115": "Kiribati",
    "116": "Korea, Democratic People's Republic Of",
    "117": "Korea, Republic Of",
    "118": "Kuwait",
    "119": "Kyrgyzstan",
    "120": "Lao People's Democratic Republic",
    "121": "Latvia",
    "122": "Lebanon",
    "123": "Lesotho",
    "124": "Liberia",
    "125": "Libyan Arab Jamahiriya",
    "126": "Liechtenstein",
    "127": "Lithuania",
    "128": "Luxembourg",
    "129": "Macao",
    "130": "Macedonia, The Former Yugoslav Republic Of",
    "131": "Madagascar",
    "132": "Malawi",
    "133": "Malaysia",
    "134": "Maldives",
    "135": "Mali",
    "136": "Malta",
    "137": "Marshall Islands",
    "138": "Martinique",
    "139": "Mauritania",
    "140": "Mauritius",
    "141": "Mayotte",
    "142": "Mexico",
    "143": "Micronesia, Federated States Of",
    "144": "Moldova, Republic Of",
    "145": "Monaco",
    "146": "Mongolia",
    "147": "Montserrat",
    "148": "Morocco",
    "149": "Mozambique",
    "150": "Myanmar",
    "151": "Namibia",
    "152": "Nauru",
    "153": "Nepal",
    "154": "Netherlands",
    "155": "Netherlands Antilles",
    "156": "New Caledonia",
    "157": "New Zealand",
    "158": "Nicaragua",
    "159": "Niger",
    "160": "Nigeria",
    "161": "Niue",
    "162": "Norfolk Island",
    "163": "Northern Mariana Islands",
    "164": "Norway",
    "165": "Oman",
    "166": "Pakistan",
    "167": "Palau",
    "168": "Palestinian Territory, Occupied",
    "169": "Panama",
    "170": "Papua New Guinea",
    "171": "Paraguay",
    "172": "Peru",
    "173": "Philippines",
    "174": "Pitcairn",
    "175": "Poland",
    "176": "Portugal",
    "177": "Puerto Rico",
    "178": "Qatar",
    "179": "Reunion",
    "180": "Romania",
    "181": "Russian Federation",
    "182": "Rwanda",
    "183": "Saint Helena",
    "184": "Saint Kitts And Nevis",
    "185": "Saint Lucia",
    "186": "Saint Pierre And Miquelon",
    "187": "Saint Vincent And The Grenadines",
    "188": "Samoa",
    "189": "San Marino",
    "190": "Sao Tome And Principe",
    "191": "Saudi Arabia",
    "192": "Senegal",
    "193": "Serbia And Montenegro",
    "194": "Seychelles",
    "195": "Sierra Leone",
    "196": "Singapore",
    "197": "Slovakia",
    "198": "Slovenia",
    "199": "Solomon Islands",
    "200": "Somalia",
    "201": "South Africa",
    "202": "South Georgia And The South Sandwich Islands",
    "203": "Spain",
    "204": "Sri Lanka",
    "205": "Sudan",
    "206": "Suriname",
    "207": "Svalbard And Jan Mayen",
    "208": "Swaziland",
    "209": "Sweden",
    "210": "Switzerland",
    "211": "Syrian Arab Republic",
    "212": "Taiwan, Province Of China",
    "213": "Tajikistan",
    "214": "Tanzania, United Republic Of",
    "215": "Thailand",
    "216": "Timor-Leste",
    "217": "Togo",
    "218": "Tokelau",
    "219": "Tonga",
    "220": "Trinidad And Tobago",
    "221": "Tunisia",
    "222": "Turkey",
    "223": "Turkmenistan",
    "224": "Turks And Caicos Islands",
    "225": "Tuvalu",
    "226": "Uganda",
    "227": "Ukraine",
    "228": "United Arab Emirates",
    "229": "United Kingdom",
    "230": "United States",
    "231": "United States Minor Outlying Islands",
    "232": "Uruguay",
    "233": "Uzbekistan",
    "234": "Vanuatu",
    "235": "Venezuela",
    "236": "Viet Nam",
    "237": "Virgin Islands, British",
    "238": "Virgin Islands, U.S.",
    "239": "Wallis And Futuna",
    "240": "Western Sahara",
    "241": "Yemen",
    "242": "Zambia",
    "243": "Zimbabwe",
    "245": "Curacao",
}

export const IncomeSlab = {
    "01": "Less Than One Lakhs",
    "02": "One to Five Lakhs",
    "03": "Five to Ten Lakhs",
    "04": "Ten to TwentyFive Lakhs",
    "05": "Above TwentyFive Lakhs",
    "06": "25 LAC - 1 CR",
    "07": ">1 CR"
}