import { Button, Card, CardContent, Grid, Typography } from "@mui/material"
import mainLogo from '../assets/mainlogo.png';

const UploadDigilocker = ({ loginStep, handleNext, setLoginStep }) => {
    var userData = JSON.parse(localStorage.getItem('EKYCData'))
    const handleStep = () => {
        console.log("inn");
        global.myCache.set('digiLockerStepStatus', 1, 0)
        setLoginStep(5)
        userData.appStep = 6
        localStorage.setItem('EKYCData', JSON.stringify(userData))
    }

    const handleDigiLocker = () => {

    }

    return (
        <Card className="loginCard">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className='logo'>
                        <img src={mainLogo} alt="fireSpot" height={50} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={handleDigiLocker}>Upload Documents via Digilocker</Button>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <Typography variant='body'>
                            OR
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={handleStep}>Upload Manually</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default UploadDigilocker