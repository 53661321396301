import { Container, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import StepOne from './step/stepOne';
import StepTwo from './step/stepTwo';
import StepThree from './step/stepThree';
import StepFour from './step/stepFour';
import StepFive from './step/stepFive';
import Payment from './step/Payment';
import Header from './step/header';
import UploadDigilocker from './step/UploadDigilocker';
import TextMobileStepper from './mobileStepper';
import { APIURL, GOOGLE_CLIENT_ID } from './commonUrl';
import axios from 'axios';
import KYC from './step/KYC';
import ConfirmDetails from './step/ConfimDetails';
import _ from 'lodash';
import { Buffer } from 'buffer';
import { connectPaymentSocket, join } from './PaymentSocket';
import Loader from './Loader';
import moment from 'moment';
import { GenderObj, MaritalStatus, stateObj } from './common/Common';

import { GoogleOAuthProvider } from '@react-oauth/google'
import Login from './step/Login';
import Political from './step/Political';

const { StringDecoder } = require('string_decoder');
const decoder = new StringDecoder('utf8');

const NodeCache = require("node-cache");
global.myCache = new NodeCache();

function App() {
	const delay = ms => new Promise(res => setTimeout(res, ms));
	var userData = JSON.parse(localStorage.getItem('EKYCData'))

	const [googleLogin, setGoogleLogin] = useState()
	const [gMail, setGmail] = useState();

	const [values, setValues] = useState({
		loading: false,
		mobileno: '',
		mobileotp: '',
		firstname: '',
		lastname: '',
		isGoogleEmail: 0,
		googleEmail: '',
		email: '',
		emailotp: '',
		pancardname: '',
		pancardnumber: '',
		pancarddob: '',
		pancardfathername: '',
		aadharcardAddressOne: '',
		aadharcardAddressTwo: '',
		aadharcardPinCode: '',
		aadharcardCity: '',
		aadharcardState: '',
		aadharcardCountry: '',
		ifscCode: '',
		accountNumber: '',
		verifyAccountNumber: '',
		piFatherFirstName: '',
		piFatherMiddleName: '',
		piFatherLastName: '',
		piMotherFirstName: '',
		piMotherMiddleName: '',
		piMotherLastName: '',
		piGender: 'male',
		piMaritalStatus: 'single',
		piEmailMobile: true,
		piEmailMobileText: '',
		piPolitical: false,
		piPoliticalText: '',
		clientcode: '',
		textClientCode: '',
		clientcodepkid: '',
		finalAmount: 0,
		discount: 0,
		totalAmount: 0,
		pancardBase64data: '',
		pancardUrl: '',
		pancardResponse: '',
		isPermanentSame: true,
		aadharFrontUrl: '',
		aadharBackUrl: '',
		aadharResponse: '',
		aadharcardNumber: '',
		aadharcardName: '',
		aadharcardAddress: '',
		permanentAddressOne: '',
		permanentAddressTwo: '',
		permanentPinCode: '',
		permanentCity: '',
		permanentState: '',
		appStep: 0,
		bankName: '',
		bankBranch: '',
		bankAddress: '',
		bankMicr: '',
		accountType: 'saving',
		incomeProofType: '',
		incomeProofPath: '',
		incomeProofFile: '',
		signBase64: '',
		signExtension: '',
		signCanvasBase64: '',
		signCanvasExtension: '',
		occupation: '',
		annualIncome: '',
		longTrading: '',
		taxResident: '',
		isReferral: false,
		referralName: "",
		isDelivarySlip: false,
		isNominee: false,

		nomineeOneName: '',
		nomineeOneRelation: '',
		nomineeOneShare: 100,
		nomineeOneMobile: '',
		nomineeOneIdProof: '',
		nomineeOneIdProofBase64: '',
		nomineeOneDOB: '',
		nomineeOneRelationshipGuardian: '',
		nomineeOneGuardianName: '',
		nomineeOneGuardianMobile: '',
		nomineeOneGuardianIdProof: '',
		nomineeOneGuardianIdProofBase64: '',

		nomineeTwoName: '',
		nomineeTwoRelation: '',
		nomineeTwoShare: 0,
		nomineeTwoMobile: '',
		nomineeTwoIdProof: '',
		nomineeTwoIdProofBase64: '',
		nomineeTwoDOB: '',
		nomineeTwoRelationshipGuardian: '',
		nomineeTwoGuardianName: '',
		nomineeTwoGuardianMobile: '',
		nomineeTwoGuardianIdProof: '',
		nomineeTwoGuardianIdProofBase64: '',

		nomineeThreeName: '',
		nomineeThreeRelation: '',
		nomineeThreeShare: 0,
		nomineeThreeMobile: '',
		nomineeThreeIdProof: '',
		nomineeThreeIdProofBase64: '',
		nomineeThreeDOB: '',
		nomineeThreeRelationshipGuardian: '',
		nomineeThreeGuardianName: '',
		nomineeThreeGuardianMobile: '',
		nomineeThreeGuardianIdProof: '',
		nomineeThreeGuardianIdProofBase64: '',

		ipvImgCode: '',
		ipvRandomNumber: 3284,
		applicatinono: "",
		kraStatus: '',
		kraObj: '',
		countryCode: '+91',

		equity: true,
		fno: false,
		currency: false,
		comodity: false,
		poa: true,
		poacharge: 200.00
	})
	const { mobileno, mobileotp, firstname, lastname, email, emailotp } = values

	const [errors, setErrors] = useState({
		successError: '',
		commonError: '',
		mobilenoerror: false,
		mobileotperror: false,
		firstnameerror: false,
		lastnameerror: false,
		emailerror: false,
		emailotperror: false,
		pancardnameerror: false,
		pancardnumbererror: false,
		pancarddoberror: false,
		pancardfathernameerror: false,
		aadharcardAddressOneError: false,
		aadharcardAddressTwoError: false,
		aadharcardPinCodeError: false,
		aadharcardCityError: false,
		aadharcardStateError: false,
		ifscCodeError: false,
		accountNumberError: false,
		verifyAccountNumberError: false,
		matchAccountNumberError: false,
		piFatherFirstNameError: false,
		piFatherMiddleNameError: false,
		piFatherLastNameError: false,
		piMotherFirstNameError: false,
		piMotherMiddleNameError: false,
		piMotherLastNameError: false,
		piMaritalStatusError: false,
		incomeProofTypeError: false,
		incomeProofFileError: false,
		occupationError: false,
		annualIncomeError: false,
		longTradingError: false,
		referralNameError: false,
		paymentError: false,
		signError: false,
	})

	const [loginStep, setLoginStep] = useState(0)
	useEffect(() => {
		connectPaymentSocket()
		if (userData && !_.isEmpty(userData)) {
			setLoginStep(userData.appStep)
			setTimeout(() => {
				join(userData.clientcode)
			}, 2000);
			setValues({
				...values,
				appStep: userData.appStep,
				mobileno: userData.mobileno
			})
		}
	}, [])

	useEffect(() => {
		if (googleLogin) {
			fetchData(googleLogin)
			setGmail(googleLogin)
		}
	}, [googleLogin])

	const fetchData = async (user) => {
		await axios
			.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
					Accept: 'application/json'
				}
			})
			.then((res) => {
				global.myCache.set('GoogleData', JSON.stringify(res.data), 0)
				setValues({
					...values,
					googleEmail: res.data.email,
					isGoogleEmail: 1
				})
				setTimeout(() => {
					handleNext()
				}, 1000);
			})
			.catch((err) => console.log(err));
	}

	const Occupation = [
		{
			key: '01',
			value: "Private Sector Service"
		},
		{
			key: '02',
			value: "Public Sector"
		},
		{
			key: '03',
			value: "Business"
		},
		{
			key: '04',
			value: "Professional"
		},
		{
			key: '05',
			value: "Agriculturist"
		},
		{
			key: '06',
			value: "Retired"
		},
		{
			key: '07',
			value: "Housewife"
		},
		{
			key: '08',
			value: "Student"
		},
		{
			key: '09',
			value: "Forex Dealer"
		},
		{
			key: '09',
			value: "Government Service"
		},
		{
			key: '99',
			value: "Others"
		}
	]

	const AnnualIncome = [
		{
			key: '01',
			value: "Less Than One Lakhs"
		},
		{
			key: '02',
			value: "One to Five Lakhs"
		},
		{
			key: '03',
			value: "Five to Ten Lakhs"
		},
		{
			key: '04',
			value: "Ten to TwentyFive Lakhs"
		},
		{
			key: '05',
			value: "Above TwentyFive Lakhs"
		},
	]

	const Experience = [
		{
			key: '00',
			value: 'No Experience'
		},
		{
			key: '01',
			value: "Below 1 Year"
		},
		{
			key: '02',
			value: "1-2 Year"
		},
		{
			key: '03',
			value: "2-4 Year"
		},
		{
			key: '04',
			value: "4-10 Year"
		},
		{
			key: '05',
			value: "10 Year"
		},
	]

	const handleNext = async () => {

		var step = loginStep + 1
		const passdata = {}
		setErrors({ ...errors, commonError: '' })
		if (loginStep == 0) {
			setErrors({ ...errors, emailerror: false })
			var gdata = global.myCache.get('GoogleData') ? JSON.parse(global.myCache.get('GoogleData')) : ''
			if (gdata == '') {
				if (email == "") {
					setErrors({ ...errors, emailerror: true })
					return false
				}
			}
			passdata.email = values.email != "" ? values.email : gdata.email
			passdata.isGoogleEmail = !_.isEmpty(gdata) ? 1 : 0
			passdata.step = 'email'
			await submitData(passdata, step)
		} else if (loginStep == 1) {
			if (emailotp == "") {
				setErrors({ ...errors, emailotperror: true })
				return false
			}
			passdata.email = values.email
			passdata.otp = values.emailotp
			passdata.step = 'emailotp'
			await submitData(passdata, step)
		} else if (loginStep == 2) {
			if (mobileno == "") {
				setErrors({ ...errors, mobilenoerror: true })
				return false
			} else {
				passdata.email = userData.email
				passdata.mobileno = values.mobileno
				passdata.step = 'mobileno'
				await submitData(passdata, step)
			}
		} else if (loginStep == 3) {
			if (mobileotp == "") {
				setErrors({ ...errors, mobileotperror: true })
				return false
			} else {
				passdata.email = userData.email
				passdata.mobileno = values.mobileno
				passdata.otp = values.mobileotp
				passdata.step = 'mobileotp'
				await submitData(passdata, step)
			}
		} else if (loginStep == 4) {
			passdata.email = userData.email
			passdata.clientcode = values.textClientCode == "" ? values.clientcode : values.textClientCode
			passdata.clientcodepkid = values.clientcodepkid
			passdata.step = 'clientcode'
			await submitData(passdata, step)
			// setLoginStep(step)
		} else if (loginStep == 14) {
			setLoginStep(16)
		} else if (loginStep == 7) {
			passdata.email = userData.email
			passdata.ipvImgCode = values.ipvImgCode
			passdata.extension = 'image/png'
			passdata.ipvcode = values.ipvRandomNumber
			passdata.step = 'kycipvimg'
			await submitData(passdata, step)
		} else if (loginStep == 17) {
			passdata.email = userData.email
			passdata.piPolitical = values.piPolitical
			passdata.piPoliticalText = values.piPoliticalText
			passdata.step = 'political'
			await submitData(passdata, step)
			setLoginStep(14)
		}
	}

	const submitData = async (passdata, step) => {
		let data = passdata;
		let config = {
			method: 'post',
			url: APIURL + 'createCustomer',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		await axios.request(config)
			.then((response) => {
				var result = response.data
				if (result.status == true) {
					var cnt = 0
					if (result.laststep == 'completed') {
						setErrors({ ...errors, commonError: result.message })
					} else {
						if (passdata.step == 'email' && passdata.isGoogleEmail == 1) {
							cnt = 1
							var storeObj = {
								appStep: result.appStep ? parseInt(result.appStep) : 2,
								clientcode: result.clientcode,
								email: passdata.email
							}

							localStorage.setItem('EKYCData', JSON.stringify(storeObj))

							var ipvRandom = Math.floor(1000 + Math.random() * 9999);

							setValues({
								...values,
								appStep: result.appStep ? parseInt(result.appStep) : 2,
								clientcode: result.clientcode,
								clientcodepkid: result.clientcodepk,
								finalAmount: result.pricedata.finalamount,
								discount: result.pricedata.discount,
								totalAmount: result.pricedata.totalamount,
								ipvRandomNumber: ipvRandom,
							})

							if (result.data.clientid && result.data.clientid != "") {
								join(result.data.clientid)
							}
							if (result.appStep == 5) {
								setLoginStep(5)
							}
							else {
								setLoginStep(parseInt(result.appStep ? result.appStep : 2))
							}
						}
						if (passdata.step == 'emailotp') {
							if (result.appStep > 1) {
								cnt = 1
								if (result.appStep == 5) {
									setLoginStep(5)
								} else {
									console.log("innn ", result.appStep);
									setLoginStep(parseInt(result.appStep))
								}
								var storeObj = {
									appStep: parseInt(result.appStep),
									clientcode: result.clientcode,
									email: passdata.email
								}

								localStorage.setItem('EKYCData', JSON.stringify(storeObj))

								var ipvRandom = Math.floor(1000 + Math.random() * 9999);

								setValues({
									...values,
									appStep: parseInt(result.appStep),
									clientcode: result.clientcode,
									clientcodepkid: result.clientcodepkid,
									finalAmount: result.pricedata.finalamount,
									discount: result.pricedata.discount,
									totalAmount: result.pricedata.totalamount,
									ipvRandomNumber: ipvRandom,

									firstname: result.data.firstname,
									lastname: result.data.lastname,
									piFatherFirstName: result.data.fatherfirstname,
									piFatherMiddleName: result.data.fathermiddlename,
									piFatherLastName: result.data.fatherlastname,
									piMotherFirstName: result.data.motherfirstname,
									piMotherMiddleName: result.data.mothermiddlename,
									piMotherLastName: result.data.motherlastname,

									aadharcardAddressOne: result.data.correspondenceaddress1,
									aadharcardAddressTwo: result.data.correspondenceaddress2,
									aadharcardPinCode: result.data.correspondencepincode,
									aadharcardCity: result.data.correspondencecity,
									aadharcardState: result.data.correspondencestate,

									permanentAddressOne: result.data.ispermanentsame == 1 ? result.data.correspondenceaddress1 : result.data.permanentaddress1,
									permanentAddressTwo: result.data.ispermanentsame == 1 ? result.data.correspondenceaddress2 : result.data.permanentaddress2,
									permanentCity: result.data.ispermanentsame == 1 ? result.data.correspondencecity : result.data.permanentcity,
									permanentState: result.data.ispermanentsame == 1 ? result.data.correspondencestate : result.data.permanentstate,

									pancardnumber: result.data.pannumber,
									pancardname: '',
									pancarddob: '',
									pancardfathername: result.data.fathernameaspan,

									accountNumber: result.data.bankaccountno,
									ifscCode: result.data.bankifsc,
									accountType: result.data.bankactype,
									bankMicr: result.data.bankmicr,

									piGender: result.data.gender,
									piMaritalStatus: result.data.maritalstatus,
									piPolitical: result.data.politicalaffilliated,

									occupation: result.data.occupation ? _.filter(Occupation, { key: result.data.occupation })[0].value : '',
									annualIncome: result.data.annualincome ? _.filter(AnnualIncome, { key: result.data.annualincome })[0].value : '',
									longTrading: result.data.experience ? _.filter(Experience, { key: result.data.experience })[0].value : '',
									taxResident: result.data.taxresident ? result.data.taxresident : '',
								})

								if (result.data.clientid && result.data.clientid != "") {
									join(result.data.clientid)
								}
							}
						}
						if (result.laststep == 'mobileotp') {
							setValues({
								...values,
								clientcode: result.clientcode,
								clientcodepkid: result.clientcodepkid,
								applicatinono: result.applicatinono
							})
							join(result.clientcode)
							userData.appStep = 4
							userData.clientcode = result.clientcode
							localStorage.setItem('EKYCData', JSON.stringify(userData))
						}
						if (result.laststep == 'clientcode') {
							setValues({ ...values, totalAmount: result.totalamount })
							setValues({ ...values, discount: result.discount })
							setValues({ ...values, finalAmount: result.finalamount })
							setValues({ ...values, appStep: 5 })
							setValues({ ...values, applicatinono: result.applicatinono })
							userData.appStep = 5
							localStorage.setItem('EKYCData', JSON.stringify(userData))
							// var storeObj = {
							// 	appStep: parseInt(result.appStep),
							// 	clientcode: passdata.clientcode,
							// 	email: passdata.email
							// }

							// localStorage.setItem('EKYCData', JSON.stringify(storeObj))
						}
						if(result.laststep == 'political'){
							userData.appStep = 14
							localStorage.setItem('EKYCData', JSON.stringify(userData))
						}
						// if (cnt == 0) {
						// 	setLoginStep(step)
						// }
					}
				} else {
					setErrors({ ...errors, commonError: result.message })
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const getCustomerData = async (email) => {
		let data = {
			email: email
		}

		let config = {
			method: 'post',
			url: APIURL + 'getCustomerData',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		await axios.request(config)
			.then((response) => {
				var result = response.data
				if (result.data) {
					var kraAllObjJson = {}
					if (result.data && result.data.kraobject && result.data.kraobject != "") {
						kraAllObjJson = JSON.parse(result.data.kraobject)
						console.log(kraAllObjJson);
					}
					if (userData.clientcode != '') {
						join(userData.clientcode)
					}


					setValues({
						...values,
						appStep: userData.appStep,
						email: email,
						clientcode: userData.clientcode,
						applicatinono: result.data.applicatinono,
						firstname: result.data.firstname,
						lastname: result.data.lastname,
						piFatherFirstName: result.data.fatherfirstname == null ? kraAllObjJson['APP_F_NAME'].split(" ")[0] : result.data.fatherfirstname,
						piFatherMiddleName: result.data.fathermiddlename == null ? kraAllObjJson['APP_F_NAME'].split(" ").length > 2 ? kraAllObjJson['APP_F_NAME'].split(" ")[1] : result.data.fathermiddlename : result.data.fathermiddlename,
						piFatherLastName: result.data.fatherlastname == null ? kraAllObjJson['APP_F_NAME'].split(" ").length > 2 ? kraAllObjJson['APP_F_NAME'].split(" ")[2] : kraAllObjJson['APP_F_NAME'].split(" ")[1] : result.data.fatherlastname,
						piMotherFirstName: result.data.motherfirstname,
						piMotherMiddleName: result.data.mothermiddlename,
						piMotherLastName: result.data.motherlastname,

						aadharcardAddressOne: result.data.correspondenceaddress1,
						aadharcardAddressTwo: result.data.correspondenceaddress2,
						aadharcardCity: result.data.correspondencecity,
						aadharcardState: result.data.correspondencestate,

						permanentAddressOne: result.data.ispermanentsame == 1 ? result.data.correspondenceaddress1 : result.data.permanentaddress1,
						permanentAddressTwo: result.data.ispermanentsame == 1 ? result.data.correspondenceaddress2 : result.data.permanentaddress2,
						permanentCity: result.data.ispermanentsame == 1 ? result.data.correspondencecity : result.data.permanentcity,
						permanentState: result.data.ispermanentsame == 1 ? result.data.correspondencestate : result.data.permanentstate,

						pancardnumber: result.data.pannumber,
						pancardname: result.data.nameaspan,
						pancarddob: moment(result.data.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
						pancardfathername: result.data.fathernameaspan,
						pancardResponse: result.data.panresponse ? JSON.parse(result.data.panresponse) : '',

						accountNumber: result.data.bankaccountno,
						ifscCode: result.data.bankifsc,
						accountType: result.data.bankactype,
						bankMicr: result.data.bankmicr,

						piMaritalStatus: result.data.maritalstatus,
						piPolitical: result.data.politicalaffilliated,

						occupation: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_OCC : result.data.occupation,
						annualIncome: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_INCOME : result.data.annualincome,
						longTrading: result.data.experience,
						taxResident: result.data.taxresident ? result.data.taxresident : '',

						finalAmount: result.finalamount,
						discount: result.discount,
						totalAmount: result.totalamount,

						kraStatus: result.data.kraexist,
						kraObj: result.data.kraobject ? JSON.parse(result.data.kraobject) : '',

						aadharcardAddressOne: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_COR_ADD1 : '',
						aadharcardAddressTwo: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_COR_ADD2 : '',
						aadharcardPinCode: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_COR_PINCD : '',
						aadharcardCity: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_COR_CITY : '',
						aadharcardState: !_.isEmpty(kraAllObjJson) ? stateObj[kraAllObjJson.APP_COR_STATE] : '',
						piGender: !_.isEmpty(kraAllObjJson) ? GenderObj[kraAllObjJson.APP_GEN] : result.data.gender,
						permanentAddressOne: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_PER_ADD1 : '',
						permanentAddressTwo: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_PER_ADD2 : '',
						permanentPinCode: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_PER_PINCD : '',
						permanentCity: !_.isEmpty(kraAllObjJson) ? kraAllObjJson.APP_PER_CITY : '',
						permanentState: !_.isEmpty(kraAllObjJson) ? stateObj[kraAllObjJson.APP_PER_STATE] : '',
						piMaritalStatus: !_.isEmpty(kraAllObjJson) ? MaritalStatus[kraAllObjJson.APP_MAR_STATUS] : '',
					})

				}
			})
	}

	useEffect(() => {
		if (userData && !_.isEmpty(userData)) {
			getCustomerData(userData.email)
		}
	}, [])
	// console.log(loginStep, values.appStep);
	return (
		<>
			<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
				{values.loading && <Loader setValues={setValues} values={values} />}
				{(loginStep == 5 || (values.appStep > 5 && values.appStep < 13)) && isDesktop && <Header />}
				{isMobile ?
					<>
						{loginStep != 5 && <Container className='content'>
							{loginStep == 0 && <Login setGoogleLogin={setGoogleLogin} handleNext={handleNext} setValues={setValues} values={values} errors={errors} />}
							{loginStep == 1 && <StepFour loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} setErrors={setErrors} />}
							{loginStep == 2 && <StepOne loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} />}
							{loginStep == 3 && <StepTwo loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} setErrors={setErrors} />}
							{loginStep == 4 && <StepFive loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} />}
						</Container>}
						{(loginStep == 5 || (values.appStep >= 5 && values.appStep <= 13)) && <TextMobileStepper setValues={setValues} values={values} errors={errors} setErrors={setErrors} setLoginStep={setLoginStep} />}
						{loginStep == 14 && <Container className='content'>
							<ConfirmDetails handleNext={handleNext} values={values} setLoginStep={setLoginStep} />
						</Container>}
						{loginStep == 16 &&
							<KYC handleNext={handleNext} setValues={setValues} values={values} />
						}
						{loginStep == 15 && <Container className='content'>
							<UploadDigilocker loginStep={loginStep} handleNext={handleNext} setLoginStep={setLoginStep} />
						</Container>}
					</>
					: <Container className='content'>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							{loginStep == 0 && <Login setGoogleLogin={setGoogleLogin} handleNext={handleNext} setValues={setValues} values={values} errors={errors} />}
							{loginStep == 1 && <StepFour loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} setErrors={setErrors} />}
							{loginStep == 2 && <StepOne loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} />}
							{loginStep == 3 && <StepTwo loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} setErrors={setErrors} />}
							{loginStep == 4 && <StepFive loginStep={loginStep} handleNext={handleNext} setValues={setValues} values={values} errors={errors} />}
							{(loginStep == 5 || (userData && userData.appStep >= 5 && userData.appStep <= 13)) && <Payment setValues={setValues} values={values} errors={errors} setErrors={setErrors} setLoginStep={setLoginStep} />}
							{loginStep == 14 && <ConfirmDetails handleNext={handleNext} values={values} setLoginStep={setLoginStep} />}
							{loginStep == 16 && <KYC handleNext={handleNext} setValues={setValues} values={values} />}
							{loginStep == 15 && <UploadDigilocker loginStep={loginStep} handleNext={handleNext} setLoginStep={setLoginStep} />}
							{loginStep == 17 && <Political handleNext={handleNext} values={values} errors={errors} setValues={setValues} />}
						</Stack>
					</Container>
				}

			</GoogleOAuthProvider>
		</>
	);
}

export default App;
