import { useRef } from "react";
import { Box, Card, CardContent, Grid, TextField, Typography } from "@mui/material"
import { isDesktop } from 'react-device-detect';
import Slim from '../slim/slim.react';
import IconButton from '@mui/material/IconButton';
import { FileUpload } from "@mui/icons-material";
import { APIURL } from "../commonUrl";
import axios from 'axios';
import moment from "moment";
import { CountryMaster, GenderObj, stateObj } from "../common/Common";

const PanCard = ({ setValues, values, errors }) => {
    const inputElPhoto = useRef(null);

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
        };
    }

    const getData = async () => {
        if (inputElPhoto.current != null) {
            if (inputElPhoto.current.nextElementSibling.value != '') {
                var Photobase64 = JSON.parse(inputElPhoto.current.nextElementSibling.value).output.image
                var Photoextensions = JSON.parse(inputElPhoto.current.nextElementSibling.value).output.type

                if (Photobase64 != "") {
                    setValues({ ...values, pancardBase64data: Photobase64, loading: true })
                    let data = {
                        base64data: Photobase64,
                        mobileno: values.mobileno,
                        extension: Photoextensions
                    };

                    let config = {
                        method: 'post',
                        url: APIURL + 'getPancardData',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    
                    await axios.request(config)
                        .then((response) => {
                            var result = response.data
                            if (result.status == true) {
                                if (result.data.id_type == "PAN") {
                                    // setValues({ ...values, loading: true })
                                    console.log(result.kraAllObjJson)

                                    setValues({
                                        ...values,
                                        loading: false,
                                        pancardname: result.kraAllObjJson.APP_NAME ? result.kraAllObjJson.APP_NAME : result.data.name,
                                        pancardnumber: result.kraAllObjJson.APP_PAN_NO ? result.kraAllObjJson.APP_PAN_NO : result.data.id_no,
                                        pancarddob: result.kraAllObjJson.APP_PAN_NO ? moment(result.kraAllObjJson.APP_DOB_DT, 'DD/MM/YYYY').format('YYYY-MM-DD') : moment(result.data.dob, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                        pancardfathername: result.kraAllObjJson.APP_F_NAME ? result.kraAllObjJson.APP_F_NAME : result.data.fathers_name,
                                        pancardUrl: result.photourl,
                                        pancardResponse: JSON.stringify(result.data),
                                        kraStatus: result.kraStatus,
                                        kraObj: result.kraAllObjJson,
                                        aadharcardAddressOne: result.kraAllObjJson.APP_COR_ADD1,
                                        aadharcardAddressTwo: result.kraAllObjJson.APP_COR_ADD2,
                                        aadharcardPinCode: result.kraAllObjJson.APP_COR_PINCD,
                                        aadharcardCity: result.kraAllObjJson.APP_COR_CITY,
                                        aadharcardState: stateObj[result.kraAllObjJson.APP_COR_STATE],
                                        aadharcardCountry: CountryMaster[result.kraAllObjJson.APP_COR_CTRY],
                                        piGender: GenderObj[result.kraAllObjJson.APP_GEN],
                                        permanentAddressOne: result.kraAllObjJson.APP_PER_ADD1,
                                        permanentAddressTwo: result.kraAllObjJson.APP_PER_ADD2,
                                        permanentPinCode: result.kraAllObjJson.APP_PER_PINCD,
                                        permanentCity: result.kraAllObjJson.APP_PER_CITY,
                                        permanentState: stateObj[result.kraAllObjJson.APP_PER_STATE],
                                        annualIncome: result.kraAllObjJson.APP_INCOME
                                    })
                                }
                            } else {
                                setValues({ ...values, loading: false })
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                }
            }
        }
    }

    return (
        <Box sx={isDesktop && { p: 3 }}>
            <Grid container spacing={1} className="cardtemplate">
                <Grid item xs={12} sm={4}>
                    <Box sx={{ p: 1 }}>
                        {/* service={slimService} didInit={ slimInit } */}
                        <Slim ratio="5:3" /* service={slimService} */>
                            <input type='file' ref={inputElPhoto} id='Photo' />
                        </Slim>
                        <IconButton variant="outlined" aria-label="fileupoad" sx={{ width: '100%' }}>
                            <FileUpload onClick={getData} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Name as per PAN Card
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            placeholder="Name as per PAN Card"
                            variant="outlined"
                            fullWidth
                            size='small'
                            error={errors.pancardnameerror}
                            value={values.pancardname}
                            onChange={(e) => setValues({ ...values, pancardname: e.target.value })}
                            helperText={errors.pancardnameerror && "Please Enter Name as per Pan Card "}
                        />
                    </Box>

                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            PAN Card Number
                        </Typography>
                        <TextField
                            type="text"
                            id="outlined-basic"
                            placeholder="PAN Card Number"
                            variant="outlined"
                            fullWidth
                            size='small'
                            error={errors.pancardnumbererror}
                            value={values.pancardnumber}
                            onChange={(e) => setValues({ ...values, pancardnumber: e.target.value })}
                            helperText={errors.pancardnumbererror && "Please Enter Pan Card Number "}
                        />
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Date of Birth
                        </Typography>
                        <TextField
                            type="date"
                            id="outlined-basic"
                            placeholder="Date of Birth"
                            variant="outlined"
                            fullWidth
                            size='small'
                            error={errors.pancarddoberror}
                            value={values.pancarddob}
                            onChange={(e) => setValues({ ...values, pancarddob: moment(e.target.value).format("DD-MM-YYYY") })}
                            helperText={errors.pancarddoberror && "Please Enter Date of Birth "}
                        />
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Father's Name
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            placeholder="Father's Name"
                            variant="outlined"
                            fullWidth
                            size='small'
                            error={errors.pancardfathernameerror}
                            value={values.pancardfathername}
                            onChange={(e) => setValues({ ...values, pancardfathername: e.target.value })}
                            helperText={errors.pancardfathernameerror && "Please Enter Father's Name"}
                        />
                    </Box>

                </Grid>
            </Grid>
        </Box>

    )
}

export default PanCard