import { AddCircle, CurrencyRupee } from "@mui/icons-material"
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Link, Radio, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { isDesktop } from "react-device-detect"

const PaymentDetails = ({ setValues, values, errors }) => {
    const [amount, setAmount] = useState(parseFloat(values.finalAmount) + parseFloat(values.poacharge))
    const calculateTotal = (val, type) => {
        var eqAmt = parseFloat(values.finalAmount)
        if (!val && type == 'eq') {
            eqAmt = 0
        }
        var poaAmt = parseFloat(values.poacharge)
        if (!val && type == 'poa') {
            poaAmt = 0
        }
        var amt = parseFloat(eqAmt) + parseFloat(poaAmt)
        setAmount(amt)
    }

    return (
        <Box>
            <Grid container spacing={1} className="paymentdetail cardtemplate">
                <Grid item xs={12} sm={9}>
                    <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                        Pay account opening fees
                    </Typography>
                    <Typography variant='caption' sx={{ color: 'text.disabled' }} component='div' gutterBottom>
                        Please select the segments you wish to trade in.
                    </Typography>
                    <Box component="section" sx={{ p: 1, border: '1px solid grey', mb: 2 }}>
                        <Grid container>
                            <Grid item xs={12} sm={6} className="title">
                                <FormControlLabel control={<Checkbox checked={values.equity} />} label="Equity" sx={{ color: 'text.secondary' }} onChange={(e) => (setValues({ ...values, equity: !values.equity }), calculateTotal(e.target.checked, 'eq'))} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Stack alignItems="center" direction="row" justifyContent="flex-end">
                                    <CurrencyRupee sx={{ fontSize: '16px', color: 'text.disabled', textDecorationLine: 'line-through' }} />
                                    <Typography variant='h6' sx={{ fontSize: '16px', color: 'text.disabled', textDecorationLine: 'line-through' }}>{parseFloat(values.totalAmount).toFixed(2)}</Typography>
                                    <CurrencyRupee />
                                    <Typography variant='h6'>{parseFloat(values.finalAmount).toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Typography sx={{ color: 'text.disabled' }}>
                            Buy and Sell shares, mutual funds and derivatives on NSE and BSE.
                        </Typography>
                        <Grid container spacing={1}>
                            {isDesktop && <Grid item xs={12} sm={3}>
                                <FormControlLabel control={<Checkbox checked={values.equity} />} label="Equity" sx={{ color: 'text.secondary' }} onChange={(e) => (setValues({ ...values, equity: !values.equity }), calculateTotal(e.target.checked, 'eq'))} />
                            </Grid>}
                            <Grid item xs={12} sm={3}>
                                <FormControlLabel control={<Checkbox checked={values.fno} />} label="F&O" sx={{ color: 'text.secondary' }} onChange={(e) => (setValues({ ...values, fno: !values.fno }))} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControlLabel control={<Checkbox checked={values.currency} />} label="Currency" sx={{ color: 'text.secondary' }} onChange={(e) => (setValues({ ...values, currency: !values.currency }))} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControlLabel control={<Checkbox checked={values.comodity} />} label="Commodity" sx={{ color: 'text.secondary' }} onChange={(e) => (setValues({ ...values, comodity: !values.comodity }))} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component="section" sx={{ p: 1, border: '1px solid grey', mb: 2 }}>
                        <Grid container>
                            <Grid item xs={12} sm={9} className="title">
                                <FormControlLabel control={<Checkbox checked={values.poa} />} label="POA Charges" sx={{ color: 'text.secondary' }} onChange={(e) => (setValues({ ...values, poa: !values.poa }), calculateTotal(e.target.checked, 'poa'))} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Stack alignItems="center" direction="row" justifyContent="flex-end">
                                    <CurrencyRupee />
                                    <Typography variant='h6'>{parseFloat(values.poacharge).toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Typography sx={{ color: 'text.disabled' }}>Operate account through POA per security pay-in/pay-out</Typography>
                    </Box>
                    <Box component="section" sx={{ p: 1, mb: 2 }}>
                        <Grid container>
                            <Grid item xs={12} sm={9} className="title">
                                <Typography sx={{ color: 'text.primary' }}>Total Amount</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Stack alignItems="center" direction="row" justifyContent="flex-end">
                                    <CurrencyRupee />
                                    <Typography variant='h6'>{amount.toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                    </Box>
                    {errors.paymentError && <Box component="section" sx={{ p: 1, border: '1px solid red' }}>
                        <Typography variant='subtitle' color={"error"}>Payment Failed. Please try again!</Typography>
                    </Box>}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                        Pay using
                    </Typography>
                    <FormControlLabel value="female" control={<Radio checked />} label="Card/Net Banking" sx={{ color: 'text.secondary' }} />
                </Grid>

            </Grid>

        </Box>
    )
}

export default PaymentDetails