import { Avatar, Box, Grid, Icon, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { useState } from "react"
import StepPersonalDetails from "./Payments/StepPersonalDetails"
import PanCard from "./PanCard"
import AadharCard from "./AadharCard"
import Bank from "./Bank"
import IncomeProof from "./IncomeProof"
import Signature from "./Signature"
import Loader from "../Loader"


const UploadDocument = ({ substep, setActiveStep, activeStep, setValues, values, errors }) => {    
    return (
        <Box>
            {/* {values.loading && <Loader setValues={setValues} values={values} />} */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                        Upload Documents
                    </Typography>
                    <Typography variant='caption' sx={{ color: 'text.disabled' }} component='div' gutterBottom>
                        Upload your required documents to proceed.
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        // orientation='vertical'
                        connector={<></>}
                        sx={{ width: '100%' }}
                    >
                        <Step key={1} sx={{ width: '20%' }}>
                            <StepLabel>
                                <div className='step-label'>
                                    <div>
                                        <Typography className='step-title'>{"Pan Card"}</Typography>
                                        <Typography variant='caption' className='step-subtitle'>{"Add Pan Card"}</Typography>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step key={2} sx={{ width: '20%' }}>
                            <StepLabel>
                                <div className='step-label'>
                                    <div>
                                        <Typography className='step-title'>{"Aadhar Card"}</Typography>
                                        <Typography variant='caption' className='step-subtitle'>{"Add Aadhar Card"}</Typography>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step key={3} sx={{ width: '20%' }}>
                            <StepLabel>
                                <div className='step-label'>
                                    <div>
                                        <Typography className='step-title'>{"Bank"}</Typography>
                                        <Typography variant='caption' className='step-subtitle'>{"Add Bank"}</Typography>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step key={4} sx={{ width: '20%' }}>
                            <StepLabel>
                                <div className='step-label'>
                                    <div>
                                        <Typography className='step-title'>{"Income Proof"}</Typography>
                                        <Typography variant='caption' className='step-subtitle'>{"Add Income Proof"}</Typography>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step key={5} sx={{ width: '20%' }}>
                            <StepLabel>
                                <div className='step-label'>
                                    <div>
                                        <Typography className='step-title'>{"Signature"}</Typography>
                                        <Typography variant='caption' className='step-subtitle'>{"Add Signature"}</Typography>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
            </Grid>

            {activeStep == 1 && <PanCard setValues={setValues} values={values} errors={errors} />}
            {activeStep == 2 && <AadharCard setValues={setValues} values={values} errors={errors} />}
            {activeStep == 3 && <Bank setValues={setValues} values={values} errors={errors} />}
            {activeStep == 4 && <IncomeProof setValues={setValues} values={values} errors={errors} />}
            {activeStep == 5 && <Signature setValues={setValues} values={values} errors={errors} />}
        </Box>
    )
}

export default UploadDocument