import { Button, Card, CardContent, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material"
import mainLogo from '../assets/mainlogo.png';
import { useState } from "react";

const Political = ({ handleNext, values, errors, setValues }) => {
    const [display, setDisplay] = useState(false)
    const handlePolitical = () => {
        setDisplay(!display)
        setValues({ ...values, piPolitical: !values.piPolitical })
    }
    return (
        <Card className="loginCard">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className='logo'>
                        <img src={mainLogo} alt="Logo" height={50} />
                    </Grid>                    
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            Are you affiliated to any political party?
                        </Typography>
                        <FormControlLabel control={<Switch onChange={handlePolitical} />} />
                    </Grid>
                    {display && <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            // placeholder="Last Name"
                            variant="outlined"
                            fullWidth
                            size='small'
                            // error={errors.piMotherLastNameError}
                            value={values.piPoliticalText}
                            onChange={(e) => setValues({ ...values, piPoliticalText: e.target.value })}
                        // helperText={errors.piMotherLastNameError && "Please Enter Last Name "}
                        />
                    </Grid>}
                    {errors.commonError != '' && <Grid item xs={12}>
                        <Typography variant='body2' color={"error"}>
                            {errors.commonError}
                        </Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={handleNext}>Continue</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Political