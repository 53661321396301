// import { Image } from "@mui/icons-material"
import * as React from 'react';
import { Button, Card, CardContent, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import mainLogo from '../assets/mainlogo.png';
// import { GoogleLogin, googleLogout, hasGrantedAnyScopeGoogle, useGoogleLogin } from '@react-oauth/google';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import axios from 'axios';
import { CountryCode } from '../common/CountryCode';

const StepOne = ({ loginStep, handleNext, setValues, values, errors }) => {
    return (
        <Card className="loginCard">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className='logo'>
                        <img src={mainLogo} alt="Logo" height={50} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" color={'GrayText'}>
                            Welcome to Pure Broking!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            Please sign-up or continue your incomplete application
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '25px' }}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <Select
                                size='small'
                                sx={{ width: '75px' }}
                                defaultValue={'+91'}
                                value={values.countryCode}
                                onChange={(e) => setValues({ ...values, countryCode: e.target.value })}
                            >
                                {CountryCode.map(function (item, i) {
                                    return <MenuItem value={item.dial_code}>{item.dial_code}</MenuItem>
                                })}
                            </Select>
                            <TextField
                                error={errors.mobilenoerror}
                                type='number'
                                id="outlined-basic"
                                label="Mobile No."
                                variant="outlined"
                                fullWidth
                                size='small'
                                autoFocus
                                // InputProps={{
                                //     disableUnderline: true
                                // }}
                                inputProps={{
                                    maxLength: '10',
                                    minLength: '10'
                                }}
                                maxLength="10"
                                minLength='10'
                                value={values.mobileno}
                                onChange={(e) => e.target.value.length <= e.target.maxLength && setValues({ ...values, mobileno: e.target.value })}
                                helperText={errors.mobilenoerror && "Please Enter Mobile No."}
                            />
                        </Stack>
                    </Grid>
                    {errors.commonError != '' && <Grid item xs={12}>
                        <Typography variant='body2' color={"error"}>
                            {errors.commonError}
                        </Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={handleNext}>Continue</Button>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}

export default StepOne