import { Box, FormControlLabel, Grid, Radio, RadioGroup, Switch, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { isDesktop } from "react-device-detect"

const PersonalInfo = ({ setValues, values, errors }) => {
    const [display, setDisplay] = useState(false)
    const [displayEmail, setDisplayEmail] = useState(true)
    const handleEmail = () => {
        setDisplayEmail(!displayEmail)
        setValues({ ...values, piEmailMobile: !values.piEmailMobile })
    }

    const handlePolitical = () => {
        setDisplay(!display)
        setValues({ ...values, piPolitical: !values.piPolitical })
    }

    return (
        <Box>
            <Grid container spacing={1} className="paymentdetail cardtemplate">
                {isDesktop && <Grid item xs={12}>
                    <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                        Personal Info
                    </Typography>
                    <Typography variant='caption' sx={{ color: 'text.disabled' }} component='div' gutterBottom>
                        Enter Your Personal Info
                    </Typography>
                </Grid>}
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Father's/Spouce's Name
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="outlined-basic"
                                placeholder="First Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                error={errors.piFatherFirstNameError}
                                value={values.piFatherFirstName}
                                onChange={(e) => setValues({ ...values, piFatherFirstName: e.target.value })}
                                helperText={errors.piFatherFirstNameError && "Please Enter First Name "}                                
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="outlined-basic"
                                placeholder="Middle Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                error={errors.piFatherMiddleNameError}
                                value={values.piFatherMiddleName}
                                onChange={(e) => setValues({ ...values, piFatherMiddleName: e.target.value })}
                                helperText={errors.piFatherMiddleNameError && "Please Enter Middle Name "}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="outlined-basic"
                                placeholder="Last Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                error={errors.piFatherLastNameError}
                                value={values.piFatherLastName}
                                onChange={(e) => setValues({ ...values, piFatherLastName: e.target.value })}
                                helperText={errors.piFatherLastNameError && "Please Enter Last Name "}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Mother's Name
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="outlined-basic"
                                placeholder="First Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                error={errors.piMotherFirstNameError}
                                value={values.piMotherFirstName}
                                onChange={(e) => setValues({ ...values, piMotherFirstName: e.target.value })}
                                helperText={errors.piMotherFirstNameError && "Please Enter First Name "}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="outlined-basic"
                                placeholder="Middle Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                error={errors.piMotherMiddleNameError}
                                value={values.piMotherMiddleName}
                                onChange={(e) => setValues({ ...values, piMotherMiddleName: e.target.value })}
                                helperText={errors.piMotherMiddleNameError && "Please Enter Middle Name "}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="outlined-basic"
                                placeholder="Last Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                error={errors.piMotherLastNameError}
                                value={values.piMotherLastName}
                                onChange={(e) => setValues({ ...values, piMotherLastName: e.target.value })}
                                helperText={errors.piMotherLastNameError && "Please Enter Last Name "}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Gender
                    </Typography>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={values.piGender}
                        onChange={(e) => setValues({ ...values, piGender: e.target.value })}  
                        helperText={errors.piMaritalStatusError && "Please Select Marital Status "}
                    >
                        <FormControlLabel value="male" control={<Radio checked={values.piGender=='Male' && true} />} label="Male" />
                        <FormControlLabel value="female" control={<Radio checked={values.piGender=='Female' && true} />} label="Female" />
                        <FormControlLabel value="transgender" control={<Radio checked={values.piGender=='Transgender' && true} />} label="Transgender" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Marital Status
                    </Typography>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={values.piMaritalStatus}
                        onChange={(e) => setValues({ ...values, piMaritalStatus: e.target.value })}
                    >
                        <FormControlLabel value="single" control={<Radio checked={values.piMaritalStatus=='single' && true} />} label="Single" />
                        <FormControlLabel value="married" control={<Radio checked={values.piMaritalStatus=='married' && true} />} label="Married" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Email & Mobile provided here belongs to you?
                    </Typography>
                    <FormControlLabel control={<Switch defaultChecked onChange={handleEmail} />} />
                </Grid>
                {displayEmail && <Grid item xs={12} sm={6}>
                    <TextField
                        id="outlined-basic"
                        placeholder="Please enter name of operating to mobile no"
                        variant="outlined"
                        fullWidth
                        size='small'
                        // error={errors.piMotherLastNameError}
                        value={values.piEmailMobileText}
                        onChange={(e) => setValues({ ...values, piEmailMobileText: e.target.value })}
                    // helperText={errors.piMotherLastNameError && "Please Enter Last Name "}
                    />
                </Grid>}
                {/* <Grid item xs={12} sm={6}>
                    <Typography variant='body2'>
                        Are you affiliated to any political party?
                    </Typography>
                    <FormControlLabel control={<Switch onChange={handlePolitical} />} />
                </Grid> */}
                {display && <Grid item xs={12} sm={6}>
                    <TextField
                        id="outlined-basic"
                        // placeholder="Last Name"
                        variant="outlined"
                        fullWidth
                        size='small'
                        // error={errors.piMotherLastNameError}
                        value={values.piPoliticalText}
                        onChange={(e) => setValues({ ...values, piPoliticalText: e.target.value })}
                    // helperText={errors.piMotherLastNameError && "Please Enter Last Name "}
                    />
                </Grid>}
            </Grid>
        </Box>
    )
}

export default PersonalInfo