import { Box, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { isDesktop } from 'react-device-detect';
import { APIURL } from "../commonUrl";
import axios from 'axios';
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Bank = ({ setValues, values, errors }) => {
    const getBankDetails = async () => {
        let data = {
            ifscCode: values.ifscCode.toUpperCase(),
        };
        await axios({
            method: "POST",
            url: APIURL + "getBankDetailsByIFSC",
            data: data,
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                var result = response.data
                if (result.success == true) {
                    setValues({
                        ...values,
                        bankName: result.records.BANK,
                        bankBranch: result.records.BRANCH,
                        bankAddress: result.records.ADDRESS,
                        bankMicr: result.records.MICR,
                    })
                }

            })
    }
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box sx={isDesktop && { p: 3 }}>
            <Grid container spacing={1} className="cardtemplate">
                <Grid item xs={12} sm={6}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            IFSC Code
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            placeholder="IFSC Code"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={values.ifscCode}
                            onChange={(e) => setValues({ ...values, ifscCode: e.target.value })}
                            error={errors.ifscCodeError}
                            helperText={errors.ifscCodeError && "Please Enter IFSC Code "}
                            onBlur={getBankDetails}
                        />
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Account Type
                        </Typography>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={values.accountType}
                            onChange={(e) => setValues({ ...values, accountType: e.target.value })}
                        >
                            <FormControlLabel value="saving" control={<Radio />} label="Saving" />
                            <FormControlLabel value="current" control={<Radio />} label="Current" />
                        </RadioGroup>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Account Number
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            placeholder="Account Number"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={values.accountNumber}
                            onChange={(e) => setValues({ ...values, accountNumber: e.target.value })}
                            error={errors.accountNumberError}
                            helperText={errors.accountNumberError && "Please Enter Account Number "}
                        />
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Verify Account Number
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                            <OutlinedInput
                                id="outlined-basic"
                                placeholder="Verify Account Number"
                                variant="outlined"
                                fullWidth
                                size='small'
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                value={values.verifyAccountNumber}
                                onChange={(e) => setValues({ ...values, verifyAccountNumber: e.target.value })}
                                error={errors.verifyAccountNumberError}
                                helperText={errors.verifyAccountNumberError && "Please Enter Verify Account Number "}
                            />
                        </FormControl>
                        {errors.matchAccountNumberError && <Typography variant='body2' color={'error'}>
                            Account Number and Verify Account Number Does not Match
                        </Typography>}
                    </Box>
                </Grid>

                {values.bankName != "" && <Grid item xs={12} sm={6}>
                    <Typography variant="button" component={"div"}>Bank Name : {values.bankName}</Typography>
                    <Typography variant="button" component={"div"}>Address : {values.bankAddress}</Typography>
                    <Typography variant="button" component={"div"}>Branch : {values.bankBranch}</Typography>
                    <Typography variant="button" component={"div"}>MICR : {values.bankMicr}</Typography>
                    {/* <Typography variant="button" component={"div"}>City : </Typography>
                    <Typography variant="button" component={"div"}>State : </Typography> */}
                </Grid>}
            </Grid>
        </Box>
    )
}

export default Bank