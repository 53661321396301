import { Box, FormControlLabel, Grid, Switch, TextField, Typography, Button } from "@mui/material"
import { useEffect, useState } from "react";
import { isDesktop } from 'react-device-detect';
import { APIURL } from "../commonUrl";
import axios from 'axios';
import moment from "moment";

const AadharCard = ({ setValues, values, errors }) => {
    const [display, setDisplay] = useState(false)
    const [backfile, setbackfile] = useState(true)
    const [files, setFiles] = useState({
        front: '',
        back: '',
    })

    useEffect(() => {
        if (files) {
            if (files.front.length > 0 && files.front[0].type == 'application/pdf') {
                setbackfile(false)
            } else {
                setbackfile(true)
            }
        }
    }, [files])

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
        };
    }

    const getData = async () => {
        // var Filesbase64 = ''        
        if (files.front != '') {
            setValues({ ...values, loading: true })
            await getBase64(files.front[0], async (result) => {
                var frontBase64 = result;
                if (files.front[0].type != 'application/pdf') {
                    await getBase64(files.back[0], async (res) => {
                        var backBase64 = res                        
                        let data = {
                            frontbase64data: frontBase64,
                            backbase64data: backBase64,
                            mobileno: values.mobileno,
                            frontextension: files.front[0].type,
                            backextension: files.back[0].type
                        };
                        await UploadAadharCard(data)
                    });
                } else {
                    let data = {
                        frontbase64data: frontBase64,
                        backbase64data: "",
                        mobileno: values.mobileno,
                        frontextension: files.front[0].type,
                        backextension: ""
                    };
                    await UploadAadharCard(data)
                }
            });
        }
    }

    const UploadAadharCard = async (data) => {
        let config = {
            method: 'post',
            url: APIURL + 'getAadharcardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.log(data);
        await axios.request(config)
            .then((response) => {
                var result = response.data

                if (result.status == true) {
                    if (result.data.id_type == "AADHAAR") {                        
                        setValues({ ...values, loading: false })
                        setValues({
                            ...values,
                            aadharcardName: values.aadharcardName == "" ? result.data.name : values.aadharcardName,
                            aadharcardAddress: values.aadharcardAddress == "" ? result.data.address : values.aadharcardAddress,
                            aadharcardAddressOne: values.aadharcardAddressOne == "" ? result.data.address_information.address : values.aadharcardAddressOne,
                            aadharcardPinCode: values.aadharcardPinCode == "" ? result.data.address_information.pincode : values.aadharcardPinCode,
                            aadharcardCity: values.aadharcardCity == "" ? result.data.address_information.district_or_city : values.aadharcardCity,
                            aadharcardState: values.aadharcardState == "" ? result.data.address_information.state : values.aadharcardState,
                            aadharcardNumber: values.aadharcardNumber == "" ? result.data.id_no : values.aadharcardNumber,
                            aadharFrontUrl: result.frontphotourl,
                            aadharBackUrl: result.backphotourl,
                            aadharResponse: JSON.stringify(result.data)
                        })
                    }
                } else {
                    setValues({ ...values, loading: false })
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    const handlePermanentAddress = () => {
        setDisplay(!display)
        setValues({ ...values, isPermanentSame: !values.isPermanentSame })
    }

    return (
        <Box sx={isDesktop && { p: 3 }}>
            <Grid container spacing={1} className="cardtemplate">
                <Grid item xs={12} sm={5}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Upload Aadhar Card Image (Front)
                        </Typography>
                        <TextField
                            type="file"
                            id="outlined-basic"
                            placeholder="Address Line 1"
                            variant="outlined"
                            fullWidth
                            size='small'
                            name="aadharFront"
                            onChange={e => setFiles({ ...files, front: e.target.files })}
                        />
                    </Box>
                    {backfile && <Box sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            Upload Aadhar Card Image (Back)
                        </Typography>
                        <TextField
                            type="file"
                            id="outlined-basic"
                            placeholder="Address Line 1"
                            variant="outlined"
                            fullWidth
                            size='small'
                            name="aadharBack"
                            onChange={e => setFiles({ ...files, back: e.target.files })}
                        />
                    </Box>}
                    <Box sx={{ p: 1 }}>
                        <Button onClick={getData} variant="outlined">Upload</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Box sx={{ p: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2'>
                                    Address Line 1
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Address Line 1"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={errors.aadharcardAddressOneError}
                                    value={values.aadharcardAddressOne}
                                    onChange={(e) => setValues({ ...values, aadharcardAddressOne: e.target.value })}
                                    helperText={errors.aadharcardAddressOneError && "Please Enter Address "}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2'>
                                    Address Line 2
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Address Line 2"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    // error={errors.aadharcardAddressTwoError}
                                    value={values.aadharcardAddressTwo}
                                    onChange={(e) => setValues({ ...values, aadharcardAddressTwo: e.target.value })}
                                // helperText={errors.aadharcardAddressTwoError && "Please Enter Address "}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2'>
                                    Pin Code
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Pin Code"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={errors.aadharcardPinCodeError}
                                    value={values.aadharcardPinCode}
                                    onChange={(e) => setValues({ ...values, aadharcardPinCode: e.target.value })}
                                    helperText={errors.aadharcardPinCodeError && "Please Enter Pincode "}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2'>
                                    City
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="City"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={errors.aadharcardCityError}
                                    value={values.aadharcardCity}
                                    onChange={(e) => setValues({ ...values, aadharcardCity: e.target.value })}
                                    helperText={errors.aadharcardCityError && "Please Enter City "}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2'>
                                    State
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="State"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={errors.aadharcardStateError}
                                    value={values.aadharcardState}
                                    onChange={(e) => setValues({ ...values, aadharcardState: e.target.value })}
                                    helperText={errors.aadharcardStateError && "Please Enter State "}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2'>
                                    Do you have a same permanent address?
                                </Typography>
                                <FormControlLabel control={<Switch defaultChecked onChange={handlePermanentAddress} />} />
                            </Grid>
                            {display &&
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2'>
                                            Address Line 1
                                        </Typography>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="Address Line 1"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            // error={errors.aadharcardAddressOneError}
                                            value={values.permanentAddressOne}
                                            onChange={(e) => setValues({ ...values, permanentAddressOne: e.target.value })}
                                        // helperText={errors.aadharcardAddressOneError && "Please Enter Address "}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2'>
                                            Address Line 2
                                        </Typography>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="Address Line 2"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            // error={errors.aadharcardAddressTwoError}
                                            value={values.permanentAddressTwo}
                                            onChange={(e) => setValues({ ...values, permanentAddressTwo: e.target.value })}
                                        // helperText={errors.aadharcardAddressTwoError && "Please Enter Address "}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2'>
                                            Pin Code
                                        </Typography>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="Pin Code"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            // error={errors.aadharcardPinCodeError}
                                            value={values.permanentPinCode}
                                            onChange={(e) => setValues({ ...values, permanentPinCode: e.target.value })}
                                        // helperText={errors.aadharcardPinCodeError && "Please Enter Pincode "}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2'>
                                            City
                                        </Typography>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="City"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            // error={errors.aadharcardCityError}
                                            value={values.permanentCity}
                                            onChange={(e) => setValues({ ...values, permanentCity: e.target.value })}
                                        // helperText={errors.aadharcardCityError && "Please Enter City "}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2'>
                                            State
                                        </Typography>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="State"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            // error={errors.aadharcardStateError}
                                            value={values.permanentState}
                                            onChange={(e) => setValues({ ...values, permanentState: e.target.value })}
                                        // helperText={errors.aadharcardStateError && "Please Enter State "}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AadharCard